import firebase from "@/firebase/config"
import MtpLanding from "../views/MtpLanding.vue"
import MtpSubjects from "../views/MtpSubjects.vue"
import MtpSubtopics from "../views/MtpSubtopics.vue"
import MtpSets from "../views/MtpSets.vue"
import MtpPractice from "../views/MtpPractice.vue"
import MtpTips from "../views/MtpTips.vue"
import MtpExam from "../views/MtpExam.vue"
// import MtpExamHistory from "../views/MtpExamHistory.vue"
// import MtpExamReview from "../views/MtpExamReview.vue"
// import Default from "../views/Default.vue"

import { mtpExpirationGuard } from "@/utils/helper"
import store from '@/store';

async function authGuard(to, from, next) {
    /**
     * Is the user authenticated?
     */
    const isAuthenticated = firebase.auth().currentUser;
    
    if (!isAuthenticated) {
        next({ path: '/login', query: { unauthorized: 'true' } })
    } else {


        console.warn(`A flagged user is accessing the MTP reviewer.`);
        
        let authUserClaims = await fetchUserClaims();

        // check if user purchased the mtp
        if (authUserClaims.mtp) {

            // check for mtp expiration / subscription
            let nextRoutePath = await mtpExpirationGuard(authUserClaims, store.getters.user.info)
            console.log('Going to:',nextRoutePath) // if nextRoutePath is null then just proceed to the current path route.
            next(nextRoutePath);

        }else{

            // check if this user purchased caap but blocked
            // if (authUserClaims.isMtpBlocked !== undefined) {
            //     if (authUserClaims.isMtpBlocked) {
            //         next({ path: '/assessment' })
            //     }else if (authUserClaims.isCaapBlocked == false && to.path == '/caap/history') {
            //         next()
            //     }
            //     else{
            //         next({ path: '/caap/history' })
            //     }
            // }else{
            //     console.error(`You don't have access to Mtp, rerouting to /reviewers`)
            //     next({ path: '/reviewers' })
            // }

            console.error(`You don't have access to Mtp, rerouting to /reviewers`)
            next({ path: '/reviewers' })
        }


        
        // let claims = await fetchUserClaims()
        // console.log(claims)
        // if(claims.mtp){
        //     next()
        // }else{
        //     next({ path: '/', query: { error: 'unauthorized' } })
        // }
        
        /**
         * @TODO
         * Add feature that enables CAAP Mtp purchase. Make sure to add
         * the necessary claims. Once done, uncomment the section above
         * and remove the line below (i.e. next();)
         */
        // next();
    }
}

async function fetchUserClaims() {
    return new Promise(async (resolve, reject) => {
        firebase.auth().currentUser.getIdTokenResult(true)
            .then(result => {
                resolve(result.claims)
            })
            .catch(error => {
                console.error("Error getting user claims", error);
                reject(error)
            })
    })
}


export const mtpRoutes = [
    {
        path: '/mtp',
        name: 'CAAP Mtp',
        component: MtpLanding,
        beforeEnter: authGuard
    },
    {
        path: '/mtp/subjects',
        name: 'CAAP Mtp Subjects',
        component: MtpSubjects,
        beforeEnter: authGuard
    },
    {
        path: '/mtp/subtopics',
        name: 'CAAP Mtp Subtopics',
        component: MtpSubtopics,
        beforeEnter: authGuard
    },
    {
        path: '/mtp/sets',
        name: 'CAAP Mtp Sets',
        component: MtpSets,
        beforeEnter: authGuard
    },
    {
        path: '/mtp/practice',
        name: 'CAAP Mtp Practice',
        component: MtpPractice,
        beforeEnter: authGuard
    },
    {
        path: '/mtp/exam',
        name: 'CAAP Mtp Exam',
        component: MtpExam,
        beforeEnter: authGuard
    },
    {
        path: '/mtp/tips',
        name: 'CAAP Mtp Tips',
        component: MtpTips,
        beforeEnter: authGuard
    },
    // {
    //     path: '/mtp/exam',
    //     name: 'CAAP Mtp Exam',
    //     component: MtpExam,
    //     beforeEnter: authGuard
    // },
    // {
    //     path: '/mtp/history',
    //     name: 'CAAP Mtp Exam History',
    //     component: MtpExamHistory,
    //     beforeEnter: authGuard
    // },
    // {
    //     path: '/mtp/review',
    //     name: 'CAAP Mtp Exam Review',
    //     component: MtpExamReview,
    //     beforeEnter: authGuard
    // },
]