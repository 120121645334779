<script setup>

import ROUTER from "@/router";
import { ref, computed, onMounted } from "vue";
import firebase from "@/firebase/config";
import moment from "moment";
import PreExamModal from "../components/PreExamModal.vue";

/**
 * Component for the "This is Beta" announcement.
 */
import Announcement from "@/modules/caap-101/components/Announcement.vue";

import { ChevronDoubleLeftIcon, QuestionMarkCircleIcon, PaperClipIcon, LightningBoltIcon, FireIcon, ClockIcon, ChartSquareBarIcon } from "@heroicons/vue/solid";

import Thinking1 from "../assets/Thinking1.vue";
import Thinking2 from "../assets/Thinking2.vue";
import Thinking3 from "../assets/Thinking3.vue";
import Thinking4 from "../assets/Thinking4.vue";


const isWaiting = ref(true);
const user = ref(null);
const subjects = ref([]);
const selectedSubject = ref(null)
const isPreExamModalOpen = ref(false)

const sortedFilteredSubjects = computed( () => { 
    const order = [
        'identification-general',
        'identification-aircraft',
    ]

    let newSubjectsArray = [];
    console.log(subjects.value)
    order.forEach((sId)=>{

        console.log(sId)
        let subjectFound = subjects.value.find(s => s.id == sId)
        console.log(subjectFound)
        if (subjectFound) {
            newSubjectsArray.push(subjectFound);
        }    
    });

    return newSubjectsArray;

})

const icons = [Thinking1, Thinking2, Thinking3, Thinking4]

const fetchUser = () => {
    return new Promise(async (resolve, reject) => {
        let id = firebase.auth().currentUser.uid
        firebase.firestore().collection("users").doc(id).get()
            .then(doc => {
                let data = doc.data()
                data.id = doc.id
                resolve(data)
            })
            .catch((error) => {
                console.error("Error getting user document:", error)
                reject(error)
            });
    })
}

const fetchSubjects = () => {
    return new Promise(async (resolve, reject) => {
        firebase.firestore().collection("reviewers").doc("plus").collection("subjects").get()
            .then((querySnapshot) => {
                var subjects = [];
                querySnapshot.forEach((doc) => {
                    let data = doc.data()
                    data.id = doc.id
                    subjects.push(data)
                });
                resolve(subjects)
                // console.log(subjects)
            })
            .catch((error) => {
                console.error("Error getting subtopic data: ", error);
                reject(error)
            })
    })
}

const handleBeginPracticeClick = (subject) => {
    ROUTER.push({ path: `/plus/subtopics`, query: { subjectId: subject.id, subjectName: subject.name } })
}

/**
 *  Get the user's best time for a certain subtopic
 */
const getTime = (subject_id) => {
    if (user.value) {
        let subject = user.value.subjects ? user.value.subjects.filter((e) => e.id == subject_id)[0] : null;
        return subject ? formatTime(subject.time) : formatTime(0);
    }
}

/**
 * Get the user's best score for a certain subtopic
 */
 const getScore = (subject_id) => {
    if (user.value) {
        let subject = user.value.subjects ? user.value.subjects.filter((e) => e.id == subject_id)[0] : null;
        return subject ? subject.score : 0;
    }
}

/**
 * Check if a user milestone exists for a certain subtopic
 */
 const checkMilestone = (subject_id, milestone) => {
    if (user.value) {
        let subject = user.value.subjects ? user.value.subjects.filter((e) => e.id == subject_id)[0] : null;
        return subject && subject.milestones.includes(milestone) ? true : false;
    }
}

/**
 * Format's a timestamp for UI display
 * @NOTE: Utility function.
 * @TODO: Convert into reusable library
 */
 const formatTime = (time) => {
    let momentedTime = moment("2015-01-01").startOf("day").seconds(time);
    return moment(momentedTime).format("HH:mm:ss");
}

const closePreExamModal = () => {
    isPreExamModalOpen.value = false
    // selectedSubject.value = null
}

const openPreExamModal = (subject) => {
    isPreExamModalOpen.value = true
    selectedSubject.value = subject
}

onMounted(async () => {

    isWaiting.value = true;

    [user.value, subjects.value] = await Promise.all([
        fetchUser(),
        fetchSubjects()
    ]);

    // addOrderToSubjects()
    isWaiting.value = false
});
</script>
<template>    
    <div class="max-w-md mx-auto py-2">
        <div class="space-y-6 mb-20 px-4">
            <Announcement />
            <nav class="flex justify-between items-center" aria-label="Breadcrumb">
                <div class="flex items-center">
                    <router-link to="/plus" class="text-sm font-medium text-warm-gray-500 hover:text-warm-gray-600 flex items-center" aria-current="page">
                        <ChevronDoubleLeftIcon class="flex-shrink-0 h-6 w-6" aria-hidden="true"/>
                    </router-link>
                </div>
                <div class="text-sm font-medium text-warm-gray-500">
                    <span class="hidden md:block">CAAP Plus Subjects</span>
                    <span class="block md:hidden">CAAP Plus Subjects</span>
                </div>
                <button class="opacity-20 disabled:cursor-default" disabled>
                    <QuestionMarkCircleIcon class="flex-shrink-0 h-6 w-6 text-warm-gray-500 hover:text-warm-gray-600" aria-hidden="true"/>
                </button>
            </nav>
            <div v-if="!isWaiting" class="space-y-6">
                <div v-for="(subject, index) in sortedFilteredSubjects" :key="subject.id" class="relative rounded-lg overflow-hidden border border-warm-gray-300 dark:border-true-gray-700 dark:bg-true-gray-800 dark:bg-opacity-50 bg-white shadow-sm hover:border-warm-gray-400 dark:hover:border-true-gray-500 dark:focus-within:ring-true-gray-800 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-yellow-500">
                    <div class="flex-1 min-w-0 col-span-1 p-6">
                        <!-- Subject Data -->
                        <div class="focus:outline-none text-left">
                            <div class="flex dark:text-warm-gray-400 text-warm-gray-800 items-center">
                                <!-- <LightningBoltIcon class="text-text-gray-500 w-6 h-6" /> -->
                                <component :is="icons[index]" class="text-text-gray-500 w-7 h-7" />
                                <h1 class="ml-2 text-lg font-medium dark:text-warm-gray-400 text-warm-gray-800">
                                    {{subject.name}}
                                </h1>
                            </div>
                            <p class="text-sm text-warm-gray-500 mt-2 max-w-sm">
                                {{subject.description}}
                            </p>
                        </div>

                        <!-- Mock Exam Badges -->
                        <div v-if="true" class="col-span-1 mt-4">
                            <div class="flex flex-inline flex-row-reverse justify-between">
                                <div class="flex flex-inline space-x-2">
                                    <!-- Milestones -->
                                    <div v-if="checkMilestone(subject.id, 'passer')" class="bg-blue-200 flex-grow-0 rounded-full w-12 h-12 p-3">
                                        <PaperClipIcon class="text-blue-500 w-6 h-6" />
                                    </div>
                                    <div v-else
                                        class="dark:bg-true-gray-700 bg-warm-gray-200 flex-grow-0 rounded-full w-12 h-12 p-3">
                                        <PaperClipIcon class="dark:text-true-gray-600 text-warm-gray-300 w-6 h-6" />
                                    </div>
                                    <div v-if="checkMilestone(subject.id, 'speedrunner')" class="bg-purple-200 flex-grow-0 rounded-full w-12 h-12 p-3">
                                        <LightningBoltIcon class="text-purple-500 w-6 h-6" />
                                    </div>
                                    <div v-else
                                        class="dark:bg-true-gray-700 bg-warm-gray-200 flex-grow-0 rounded-full w-12 h-12 p-3">
                                        <LightningBoltIcon class="dark:text-true-gray-600 text-warm-gray-300 w-6 h-6" />
                                    </div>
                                    <div v-if="checkMilestone(subject.id, 'master')" class="bg-yellow-300 flex-grow-0 rounded-full w-12 h-12 p-3">
                                        <FireIcon class="text-yellow-600 w-6 h-6" />
                                    </div>
                                    <div v-else
                                        class="dark:bg-true-gray-700 bg-warm-gray-200 flex-grow-0 rounded-full w-12 h-12 p-3">
                                        <FireIcon class="dark:text-true-gray-600 text-warm-gray-300 w-6 h-6" />
                                    </div>
                                </div>
                                <!-- Exam Data -->
                                <div class="focus:outline-none text-left">
                                    <div class="flex text-md items-center">
                                        <ClockIcon class="w-5 h-5 dark:text-warm-gray-700 text-warm-gray-300" />
                                        <p class="ml-2 font-medium dark:text-warm-gray-700 text-warm-gray-300">{{ getTime(subject.id) }}</p>
                                    </div>
                                    <div class="flex dark:text-warm-gray-400 text-warm-gray-800 text-md items-center ">
                                        <ChartSquareBarIcon
                                            class="w-5 h-5 dark:text-warm-gray-700 text-warm-gray-300" />
                                        <p class="ml-2 font-medium dark:text-warm-gray-700 text-warm-gray-300">{{ getScore(subject.id)}}%</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Action Buttons -->
                    <div class="dark:bg-true-gray-700 dark:bg-opacity-25 bg-gray-50 flex-1 min-w-0 px-6 py-3">
                    <!-- <div class="text-sm flex justify-between">
                        <button @click="handleStudyClick(subject)" class="font-medium dark:text-yellow-400 dark:hover:text-yellow-300 text-yellow-600 hover:text-yellow-700">
                            View Subtopics
                        </button>
                    </div> -->
                    <div class="text-sm flex justify-between">
                        <button @click="handleBeginPracticeClick(subject)" class="font-medium dark:text-yellow-400 dark:hover:text-yellow-300 text-yellow-600 hover:text-yellow-700">
                        Begin Practice
                        </button>
                        <button @click="openPreExamModal(subject)" class="font-medium dark:text-indigo-400 dark:hover:text-indigo-300 text-indigo-700 hover:text-indigo-900">
                        Start Mock Exam
                        </button>
                    </div>
                    </div>
                </div>
            </div>
            <div v-else>
            <div class="flex justify-center mt-8">
                <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-true-gray-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
            </div>
            </div>
        </div>

        <PreExamModal :isOpen="isPreExamModalOpen" :subject="selectedSubject" @closeModal="closePreExamModal()" />

    </div>
</template>