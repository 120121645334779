<script setup>

import ROUTER from "@/router"
import { ref, computed } from "vue"
import firebase from "@/firebase/config"
import { ArrowRightIcon, ChevronDoubleLeftIcon, QuestionMarkCircleIcon, PaperClipIcon, LightningBoltIcon, FireIcon } from "@heroicons/vue/solid"

const isWaiting = ref(false)
const subtopics = ref([])
const tagsQuestions = ref([])
const setMapping = ref(null)

const reviewerId = 'mtp';
const tags = ref([])
const subjectId = computed( () => ROUTER.currentRoute.value.query.subjectId )
const subjectName = computed( () => ROUTER.currentRoute.value.query.subjectName )

const subtopicId = computed( () => ROUTER.currentRoute.value.query.subtopicId )
const subtopicName = computed( () => ROUTER.currentRoute.value.query.subtopicName )
const currentSubtopicSets = computed( () => {
    let _temp = tags.value.sort().filter( e => e.shorthand.includes(`${subtopicId.value}-set`) ) // find sets
    return _temp.sort((a, b) => a.name.localeCompare(b.name)) // sort alphabetically by set namae
})

const currentSubtopicQuestionTypes = computed( () => {
    let _temp = tags.value.sort().filter( e => e.shorthand.includes(`question-type`) ) // find sets
    return _temp.sort((a, b) => a.name.localeCompare(b.name)) // sort alphabetically by set namae
})

const fetchTagsByReviewer = (reviewerId) => {
    return new Promise(async (resolve, reject) => {
        firebase.firestore().collection("tags").where("reviewerId", "==", reviewerId).get()
            .then((querySnapshot) => {
                var tags = [];
                querySnapshot.forEach((doc) => {
                    let data = doc.data()
                    data.id = doc.id
                    tags.push(data)
                });
                resolve(tags)
            })
            .catch((error) => {
                console.error("Error getting subtopic data: ", error);
                reject(error)
            })

    })   
}

const generateQuestionToQuestionTypeMap = () => {

    // get the current subtopic sets and loop over each
    // one to identify the 

    let setArray = [];

    let questionTypes = tags.value.filter( e => e.shorthand.includes(`question-type-`));

    
    currentSubtopicSets.value.forEach( set => {

        // console.log(`****** SET: ${set.name} *****`)

        let data = {
            setId: set.id,
            questionToQuestionTypeMap: null,
        }

        // filter all questions that are under this set
        let questionsArray = tagsQuestions.value.map( e => { if(e.tagId == `${set.id}`) return e.questionId }).filter(value => value)
        if(questionsArray.length > 0){

            // foreach question type, find whether or not there are any tagsQuestions linked
            const questionToQuestionTypeMap = new Map();
            questionTypes.forEach(questionType => {
                
                // get all tag==questions links that are of 'question-type'
                let questionTypes = tagsQuestions.value.filter( e => e.tagId == questionType.id) 
                
                // number of tag==questions links that are of 'question-types' but also are under this set
                let questionTypesInThisSet = questionTypes.filter( e => questionsArray.includes(e.questionId)) 

                // only do a question to questionType map if there are actually questionTypes for this set (which also means question array is not empty)
                if(questionTypesInThisSet.length > 0){
                    
                    // console.log(questionsArray)
                    questionsArray.forEach( qId => {
                        
                        // console.log({questionTypesInThisSet})
                        // console.log(`finding ${qId}`)
                        let questionToQuestionTypeMatch = questionTypesInThisSet.filter( e => e.questionId == qId)
                        
                        if(questionToQuestionTypeMatch.length > 0){
                            // console.log(qId, '--', questionToQuestionTypeMatch[0].tagId)
                            questionToQuestionTypeMap.set(qId, questionToQuestionTypeMatch[0].tagId)
                        }
                        
                    });
                    
                    // console.log(`----`)
                    // console.log(questionsArray)
                    // console.log(questionType_data)
                    // console.log(questionTypesInThisSet)
                    // console.log(questionToQuestionTypeMap)

                }

            });

            data.questionToQuestionTypeMap = questionToQuestionTypeMap

        }

        // set the data and its mapping
        setArray.push(data)

    });

    console.log(setArray)
    return setArray

}

const fetchTagsQuestions = () => {
    return new Promise(async (resolve, reject) => {
        console.log(subjectId.value)
        firebase.firestore().collection("tags_questions").where('questionnaireId', '==', subtopicId.value).get()
            .then((querySnapshot) => {
                var temp = [];
                querySnapshot.forEach((doc) => {
                    let data = doc.data()
                    data.id = doc.id
                    temp.push(data)
                });
                resolve(temp)
            })
            .catch((error) => {
                console.error("Error getting tags: ", error);
                reject(error)
            })
    })
}

const getNumberOfItemsInSet = (setId) => {

    let mapping = setMapping.value.filter(e => e.setId == setId)[0].questionToQuestionTypeMap
    if(mapping){
        return mapping.size
    }else{
        return 0
    }

}

const questionItems = (setId) => {
    let mapping = setMapping.value.filter(e => e.setId == setId)[0].questionToQuestionTypeMap
    if(mapping){
        let _data = new Map();
        Array.from(mapping).forEach( elem => {
            (_data.has(elem[1])) ? 
            _data.set(elem[1], _data.get(elem[1])+1 ) : 
            _data.set(elem[1], 1)
        });
        return _data
    }else{
        return null
    }
}

const handlePracticeClick = (set) => {
    ROUTER.push({ path: `/mtp/practice`, query: { setId: set.id,setName: set.name, subtopicName: subtopicName.value, subtopicId: subtopicId.value, subjectName: subjectName.value, subjectId: subjectId.value, reviewerId: reviewerId } })
}

const initialize = async () => {
    isWaiting.value = true
    if(subtopicId.value){
        tags.value = await fetchTagsByReviewer(reviewerId)
        if(tags.value.length > 0){
            tagsQuestions.value = await fetchTagsQuestions()
            isWaiting.value = false
            setMapping.value = generateQuestionToQuestionTypeMap()

        }else{
            console.error("No results found. Please make sure you have correct subtopic Id.")
        }
    }else{
        console.error("There is no subtopic being passed. You will be redirected")
    }
}
initialize()
</script>
<template>
    <div class="max-w-md mx-auto py-6">
        <div class="space-y-6 mb-20 px-4">
            <nav class="flex justify-between items-center" aria-label="Breadcrumb">
                <div class="flex items-center">
                    <router-link :to="`/mtp/subtopics?subjectId=${subjectId}&subjectName=${subjectName}`" class="text-sm font-medium text-warm-gray-500 hover:text-warm-gray-600 flex items-center" aria-current="page">
                        <ChevronDoubleLeftIcon class="flex-shrink-0 h-6 w-6" aria-hidden="true"/>
                    </router-link>
                </div>
                <div class="text-sm font-medium text-warm-gray-500">
                    <span class="hidden md:block">Sets under {{ subtopicName }}</span>
                    <span class="block md:hidden">Sets under {{ subtopicName }}</span>
                </div>
                <button class="opacity-20 disabled:cursor-default" disabled>
                    <QuestionMarkCircleIcon class="flex-shrink-0 h-6 w-6 text-warm-gray-500 hover:text-warm-gray-600" aria-hidden="true"/>
                </button>
            </nav>
            <div v-if="!isWaiting">
                <ul v-if="currentSubtopicSets.length > 0" class="space-y-6">
                    <!-- <li v-for="set in currentSubtopicSets" :key="set.shorthand" class="my-8 border border-white rounded-md px-8 py-4">
                        <h1 class="my-2 text-lg font-bold">{{ set.name }}</h1>
                        <p>{{ set }}</p>
                        <p>
                            {{ getNumberOfItemsInSet(set.id) }} Items
                        </p>
                        <div class="flex justify-between text-yellow-400 my-2">
                            <button @click="handlePracticeClick(set)">Start Practice</button>
                        </div>
                    </li> -->

                    <div  v-for="set in currentSubtopicSets" :key="set.id" class="text-base rounded-2xl border border-true-gray-700 dark:bg-true-gray-800 dark:bg-opacity-50 bg-white border-opacity-50">
                    <div class="focus:outline-none px-6 py-6 ">
                        <div class="text-base font-medium text-warm-gray-400 flex justify-between items-start">
                            <div class="flex flex-inline items-start">
                            <!-- <CheckCircleIcon class="text-warm-gray-600 w-6 h-6 mr-1 flex-shrink-0" /> -->
                            <h1 class="text-base font-medium dark:text-warm-gray-400 text-warm-gray-800">
                                {{set.name}}
                            </h1>
                            </div>
                        </div>
                        <p class="text-sm text-warm-gray-500 mt-2">
                            <span v-html="set.description"></span>
                        </p>
                        <div class="text-sm text-warm-gray-500">
                        <!-- <div class="text-sm text-warm-gray-500 mt-4 border-t border-warm-gray-700 pt-4"> -->
                            <!-- <p>This set has a total of {{ getNumberOfItemsInSet(set.id) }} items.</p> -->
                            <!-- <div v-if="getNumberOfItemsInSet(set.id) > 0">
                                <p class="mt-2">Items per Question Type:</p>
                                <ul class="list-disc pl-6">
                                    <li v-for="item in questionItems(set.id)">
                                        {{ tags.filter( e => e.id == item[0])[0].name }} has {{  item[1] }} items.
                                    </li>
                                </ul>
                            </div> -->
                            
                        </div>
                    </div>
                    <div class="dark:bg-true-gray-700 dark:bg-opacity-25 bg-gray-50 flex-1 min-w-0 px-6 py-3 rounded-b-2xl">
                        <div class="text-sm flex justify-between">
                            <button @click="handlePracticeClick(set)" class="font-medium dark:text-yellow-400 dark:hover:text-yellow-300 text-yellow-600 hover:text-yellow-700"> 
                                Begin Practice
                            </button>
                            <div class="text-white">
                                {{ getNumberOfItemsInSet(set.id) }} Items
                            </div>
                        </div>
                    </div>
                </div>
                </ul>
                <div v-else class="text-warm-gray-400 text-center text-sm">
                    No sets found under this subtopic.
                </div>
            </div>
            <div v-else>
                <div class="flex justify-center mt-8">
                    <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-true-gray-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </div>
            </div>
        </div>
    </div>
</template>