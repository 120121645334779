import { createRouter, createWebHistory } from 'vue-router'
import firebase from "@/firebase/config";
import "firebase/auth";
import "firebase/firestore";
import { userService } from "@/utils/user"
import { caapExpirationGuard } from "@/utils/helper"


import { liteRoutes } from "@/modules/lite/router"
import { mtpRoutes } from "@/modules/mtp/router"
import { caap101Routes } from "@/modules/caap-101/router"
import { rewardRoutes } from "@/modules/reward/router"
import { plusRoutes } from '@/modules/plus/router';

import store from '@/store';

// User
import Signup from '../views/user/Signup.vue'
// import SignupOld from '../views/user/SignupOld.vue'
import Login from '../views/user/Login.vue'
import Logout from '../views/user/Logout.vue'
import Onboarding from '../views/user/Onboarding.vue'
import Account from '../views/user/Account.vue'

// CAAP
import Caap from '../views/caap/Caap.vue'
import CaapHistory from '../views/caap/CaapHistory.vue'
import CaapAchievements from '../views/caap/CaapAchievements.vue'
import CaapExamView from '../views/caap/CaapExamView.vue'
// Common
import Dashboard from '../views/Dashboard.vue'
// import Reviewers from '../views/Reviewers.vue'
import ReviewersTemp from '../views/Reviewers.vue'
import Reviewers from '../views/ReviewersUpdated.vue'
// import ReviewersTemp from '../views/ReviewersUpdated.vue'
import Leaderboard from '../views/Leaderboard.vue'
import LeaderboardV2 from '../views/LeaderboardV2.vue'
import Subjects from '../views/Subjects.vue'
import Subtopics from '../views/Subtopics.vue'
import Mock from '../views/Mock.vue'
import Practice from '../views/Practice.vue'
import Faqs from '../views/Faqs.vue'
import Changelog from '../views/Changelog.vue'
import Season2 from '../views/Season2.vue'
// School
import Schools from '../views/Schools.vue'
import School from '../views/School.vue'
import Test from '../views/Test.vue'
import OnboardingCaap from '../views/OnboardingCaap.vue'
import Terms from '../views/Terms.vue'
import Privacy from '../views/Privacy.vue'
// Coupon
import CouponView from '@/views/coupon/CouponView.vue'

/**
 * Added Oct 30: Softblock feature
 */
import Assessment from '../views/assessment/Assessment.vue'
// import { softBlockedData } from "@/data/softblock.data";

const coreRoutes = [{
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
        beforeEnter: authGuard
    },
    {
        path: '/reviewers',
        name: 'Reviewers',
        component: Reviewers,
        beforeEnter: authGuard
    },
    {
        path: '/reviewers/051e0aa57f13de299bef2d0c6e59dc72',
        name: 'ReviewersTemp',
        component: ReviewersTemp,
        beforeEnter: authGuard
    },
    {
        path: '/leaderboard',
        name: 'Leaderboard',
        component: LeaderboardV2,
        beforeEnter: authGuard
    },
    // {
    //   path: '/lb2',
    //   name: 'LeaderboardV2', component: LeaderboardV2, beforeEnter: authGuard
    // },
    // {
    //   path: '/season2',
    //   name: 'Season 2', component: Season2, beforeEnter: authGuard
    // },

    /** 💊 The path below is commented. This is a backdoor for admin on emergency purposes. */
    // {
    //   path: '/a31229e4-7a4c-4588-bdca-d46a253715f3',
    //   name: 'Signup', 
    //   component: Signup, 
    //   // beforeEnter: redirectIfLogged
    // },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        beforeEnter: redirectIfLogged
    },
    {
        path: '/logout',
        name: 'Logout',
        component: Logout,
        beforeEnter: authGuard
    },
    {
        path: '/caap',
        name: 'Caap',
        component: Caap,
        beforeEnter: authGuard
    },
    {
        path: '/caap/history',
        name: 'Caap History',
        component: CaapHistory,
        beforeEnter: authGuard
    },
    {
        path: '/caap/history/mock/:exam_id',
        name: 'Caap Exam History',
        component: CaapExamView,
        beforeEnter: authGuard
    },
    {
        path: '/caap/achievements',
        name: 'Caap Achievements',
        component: CaapAchievements,
        beforeEnter: authGuard
    },
    {
        path: '/caap/course/:course',
        name: 'Subjects',
        component: Subjects,
        beforeEnter: authGuard
    },
    {
        path: '/caap/course/:course/:subject',
        name: 'Subtopics',
        component: Subtopics,
        beforeEnter: authGuard
    },
    {
        path: '/caap/course/:course/:subject/mock',
        name: 'Mock',
        component: Mock,
        beforeEnter: authGuard
    },
    {
        path: '/caap/course/:course/:subject/:subtopic/practice',
        name: 'Practice',
        component: Practice,
        beforeEnter: authGuard
    },
    {
        path: '/onboarding',
        name: 'Onboarding',
        component: Onboarding,
        beforeEnter: authGuard
    },
    {
        path: '/faqs',
        name: 'FAQs',
        component: Faqs,
        beforeEnter: authGuard
    },
    {
        path: '/terms',
        name: 'Terms',
        component: Terms,
        beforeEnter: authGuard
    },
    {
        path: '/privacy',
        name: 'Privacy',
        component: Privacy,
        beforeEnter: authGuard
    },
    {
        path: '/changelog',
        name: 'Changelog',
        component: Changelog,
        beforeEnter: authGuard
    },
    {
        path: '/account',
        name: 'Account',
        component: Account,
        beforeEnter: authGuard
    },
    {
        path: '/schools',
        name: 'Schools',
        component: Schools,
        beforeEnter: authGuard
    },
    {
        path: '/test',
        name: 'Test',
        component: Test,
        beforeEnter: authGuard
    },
    {
        path: '/coupon',
        name: 'Coupon',
        component: CouponView,
        beforeEnter: authGuard
    },
    {
        path: '/onboarding-caap',
        name: 'OnboardingCaap',
        component: OnboardingCaap,
        beforeEnter: authGuard
    },
    {
        path: '/assessment',
        name: 'Assessment',
        component: Assessment,
        beforeEnter: authGuard
    },
    {
        path: '/school/:id',
        name: 'School',
        component: School,
        beforeEnter: authGuard
    },
    {
        path: '/:pathMatch(.*)*',
        beforeEnter: redirectIfLogged,
        component: () =>
            import ( /* webpackChunkName: "login" */ '../views/user/Login.vue')
    }
]

const routes = coreRoutes.concat(liteRoutes, mtpRoutes, caap101Routes, rewardRoutes, plusRoutes)

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

async function authGuard(to, from, next) {
    const isAuthenticated = firebase.auth().currentUser;

    // if (!isAuthenticated) {
    //     next({ path: '/login', query: { unauthorized: 'true' } })
    // } else if (to.path != '/assessment' && to.path.includes("caap") && await isSoftBlocked(to)) {
    //     console.warn(`A flagged user is accessing the CAAP reviewer.`)
    //     next({ path: '/assessment' })
    // } else if (to.path == '/assessment' && await isSoftBlockedUnblocked(to)) {
    //     next();
    // } else if (to.path == '/assessment' && await isSoftBlocked(to) == false) {
    //     console.warn(`A flagged user is accessing the assessment but not blocked.`)
    //     next({ path: '/reviewers' })
    // } else if (to.path != '/caap/history' && to.path.includes("caap/course") && await isSoftBlockedUnblocked(to)) {
    //     console.warn(`A flagged user is accessing the CAAP Course.`)
    //     next({ path: '/caap/history' })
    // } else {
    //     next();
    // }

    if (!isAuthenticated) {
      next({ path: '/login', query: { unauthorized: 'true' } })
    // } else if (to.path != '/assessment' && to.path.includes("caap")) {
    } else if (to.path.includes("caap")) {

        console.warn(`A flagged user is accessing the CAAP reviewer.`);
        
        let authUserClaims = await userService.fetchUserClaims(firebase.auth());

        // check if user purchased the caap
        if (authUserClaims.caap) {

             // check for caap expiration / subscription
            let nextRoutePath = await caapExpirationGuard(authUserClaims, store.getters.user.info)
            console.log('Going to:',nextRoutePath) // if nextRoutePath is null then just proceed to the current path route.
            next(nextRoutePath);

        }else{
            console.error(`You don't have access to CAAP, rerouting to /reviewers`)
            next({ path: '/reviewers' })
            // check if this user purchased caap but blocked
            // if (authUserClaims.isCaapBlocked !== undefined) {
            //     if (authUserClaims.isCaapBlocked) {
            //         // next({ path: '/assessment' })
            //         next({ path: '/reviewers' })
            //     }else if (authUserClaims.isCaapBlocked == false && to.path == '/caap/history') {
            //         next()
            //     }
            //     else{
            //         next({ path: '/caap/history' })
            //     }
            // }else{
            //     console.error(`You don't have access to CAAP, rerouting to /reviewers`)
            //     next({ path: '/reviewers' })
            // }
        }
        
       
    // } else if (to.path == '/assessment' && await isSoftBlockedUnblocked(to)) {
    //     next();
    // } else if (to.path == '/assessment' && await isSoftBlocked(to) == false) {
    //     console.warn(`A flagged user is accessing the assessment but not blocked.`)
    //     next({ path: '/reviewers' })
    // } else if (to.path != '/caap/history' && to.path.includes("caap/course") && await isSoftBlockedUnblocked(to)) {
    //     console.warn(`A flagged user is accessing the CAAP Course.`)
    //     next({ path: '/caap/history' })
    } else if (to.path == '/assessment' && await canAccessAssessmentPage() == false) {
        console.error(`You don't have access to asessment page...`)
        next({ path: '/' })
    } else {
        console.log('Proceeding to:', to.path);
        next();
    }

}

function redirectIfLogged(to, from, next) {
    const isAuthenticated = firebase.auth().currentUser;
    (isAuthenticated) ? next({ path: '/' }): next();
}


async function canAccessAssessmentPage(){
    console.log('Checking if user can access assessment page.')
    let authUserClaims = await userService.fetchUserClaims(firebase.auth())
    return authUserClaims.isCaapBlocked !== undefined
}


/**
 * This function will check if the logged in user is soft blocked.
 */
async function isSoftBlocked(to) {
    /**
     * @TODO
     * Please add the 'blocked' in token claims so that we don't have 
     * to query to the database to check whether the logged in user is blocked.
     * For testing purposes, we hardcoded the list of softblocked users
     * under data/softblock.data.js
     * 
     * Comment last updated: 11/09/2023 by @john
     */
    // let userEmail = firebase.auth().currentUser.email;
    console.log('Checking if user is not blocked to access the CAAP...')
    let authUserClaims = await userService.fetchUserClaims(firebase.auth())
    return authUserClaims.isBlocked && authUserClaims.isCaapExpired && authUserClaims.caap == false;
}


/**
 * This function will check if the logged in user is unblocked from soft blocked.
 */
async function isSoftBlockedUnblocked(to) {
    // let isAccessingCaapCourse = to.path.includes("caap/course")
    let authUserClaims = await userService.fetchUserClaims(firebase.auth())
    return authUserClaims.isCaapExpired && authUserClaims.isBlocked == false
}

export default router



/*

PAGES:
  - Dashboard           - /
  - Reviewers           - /reviewers/
    - CAAP_Home         - /caap/
      - CAAP_Subjects   - /caap/:course
      - CAAP_Subtopics  - /caap/:course/:subject
      - CAAP_Practice   - /caap/:course/:subject/:subtopic/practice
      - CAAP_Mock       - /caap/:course/:subject/mock
  - Leaderboard         - /leaderboard
  - Account             - /account (User can edit his details here)
*/

/**
 * Refactored for v0.1.0 on 11/12/2021
 */