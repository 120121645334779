<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="h-100">
    <LoadingScreen v-if="isLoading" />
    <main v-else class="mt-4 sm:mt-8 pb-8">
      <div class="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <h1 class="sr-only">Dashboard</h1>

        <!-- Main 3 column grid -->
        <div class="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-5">

          <!-- Right column -->
          <div class="grid grid-cols-1 gap-5">

            <!-- Profile Overview -->
            <section aria-labelledby="profile-overview">
              <div class="rounded-lg dark:bg-true-gray-800 bg-white overflow-hidden shadow">
                <h2 class="sr-only" id="profile-overview-title">Profile Overview</h2>
                <div class="dark:bg-true-gray-800 bg-white p-6">
                  <div class="">
                    <div class="flex flex-col">
                      <div class="flex-shrink-0">
                        <img v-if="user.photo" class="object-cover mx-auto h-20 w-20 rounded-full" :src="user.photo"
                          alt="" />
                        <DefaultUser class="block mx-auto h-20 w-20" v-else />
                      </div>
                      <div class="mt-4 text-center">
                        <p class="text-sm font-medium text-warm-gray-500">Welcome back,</p>
                        <div class="flex justify-center items-center">
                          <p
                            class="text-xl font-bold sm:text-2xl dark:text-warm-gray-300 text-warm-gray-800 flex flex-inline items-center space-x-1">
                            <span>{{ user.firstname }} {{ user.lastname }}</span>
                            <BadgeCheckIcon v-if="user.license && user.license.isVerified"
                              class="w-5 h-5 text-blue-500" />
                          </p>
                          <router-link to="/account" @click="mobileMenuOpen = false">
                            <PencilAltIcon
                              class="ml-2 mb-1 inline-block w-4 h-4 dark:text-warm-gray-400 text-warm-gray-600" />
                          </router-link>
                        </div>
                        <p class="text-sm font-medium dark:text-warm-gray-400 text-warm-gray-600">{{
                          getSchoolById(schools, user.school).name }} - {{ (user.course.includes("(AE)")) ?
    user.course.substr(-3, 2) : user.course.substr(-4, 3) }}</p>
                      </div>
                    </div>

                    <!-- Badges -->
                    <div class="my-4 flex flex-row -space-x-2 justify-center">

                      <!-- AMT -->
                      <div v-if="(user.achievements && user.achievements.includes('full-master-amt'))" :class="[
                        (user.achievements && user.achievements.includes('full-master-amt'))
                          ? 'bg-yellow-200 text-yellow-500 dark:bg-yellow-300 dark:text-yellow-500 '
                          : 'bg-true-gray-200 text-true-gray-300 dark:bg-true-gray-700 dark:text-true-gray-600 '
                        , 'flex-grow-0 rounded-full w-16 h-16 p-3 border-4 border-solid dark:border-true-gray-800 border-white'
                      ]">
                        <AllMasterLogo />
                      </div>
                      <div v-else-if="(user.achievements && user.achievements.includes('full-speedrunner-amt'))" :class="[
                        (user.achievements && user.achievements.includes('full-speedrunner-amt'))
                          ? 'bg-yellow-200 text-yellow-500 dark:bg-yellow-300 dark:text-yellow-500 '
                          : 'bg-true-gray-200 text-true-gray-300 dark:bg-true-gray-700 dark:text-true-gray-600 '
                        , 'flex-grow-0 rounded-full w-16 h-16 p-3 border-4 border-solid dark:border-true-gray-800 border-white'
                      ]">
                        <AllSpeedrunnerLogo />
                      </div>
                      <div v-else :class="[
                        (user.achievements && user.achievements.includes('full-passer-amt'))
                          ? 'bg-yellow-200 text-yellow-500 dark:bg-yellow-300 dark:text-yellow-500 '
                          : 'bg-true-gray-200 text-true-gray-300 dark:bg-true-gray-700 dark:text-true-gray-600 '
                        , 'flex-grow-0 rounded-full w-16 h-16 p-3 border-4 border-solid dark:border-true-gray-800 border-white'
                      ]">
                        <AllPasserLogo />
                      </div>

                      <!-- AVT -->
                      <div v-if="(user.achievements && user.achievements.includes('full-master-avt'))" :class="[
                        (user.achievements && user.achievements.includes('full-master-avt'))
                          ? 'bg-yellow-200 text-yellow-500 dark:bg-yellow-300 dark:text-yellow-500 '
                          : 'bg-true-gray-200 text-true-gray-300 dark:bg-true-gray-700 dark:text-true-gray-600 '
                        , 'flex-grow-0 rounded-full w-16 h-16 p-3 border-4 border-solid dark:border-true-gray-800 border-white'
                      ]">
                        <AllMasterLogoHeli />
                      </div>
                      <div v-else-if="(user.achievements && user.achievements.includes('full-speedrunner-avt'))" :class="[
                        (user.achievements && user.achievements.includes('full-speedrunner-avt'))
                          ? 'bg-yellow-200 text-yellow-500 dark:bg-yellow-300 dark:text-yellow-500 '
                          : 'bg-true-gray-200 text-true-gray-300 dark:bg-true-gray-700 dark:text-true-gray-600 '
                        , 'flex-grow-0 rounded-full w-16 h-16 p-3 border-4 border-solid dark:border-true-gray-800 border-white'
                      ]">
                        <AllSpeedrunnerLogoHeli />
                      </div>
                      <div v-else :class="[
                        (user.achievements && user.achievements.includes('full-passer-avt'))
                          ? 'bg-yellow-200 text-yellow-500 dark:bg-yellow-300 dark:text-yellow-500 '
                          : 'bg-true-gray-200 text-true-gray-300 dark:bg-true-gray-700 dark:text-true-gray-600 '
                        , 'flex-grow-0 rounded-full w-16 h-16 p-3 border-4 border-solid dark:border-true-gray-800 border-white'
                      ]">
                        <AllPasserLogoHeli />
                      </div>

                      <div
                        class="flex-grow-0 rounded-md w-16 h-16 border-4 border-solid dark:border-true-gray-800 border-white">
                        <router-link :to="`/school/${getSchoolById(schools, user.school).shorthand.toLowerCase()}`"
                          class="hover:text-md">
                          <img class="object-cover mx-auto h-14 w-14 rounded-md"
                            :src="getSchoolById(schools, user.school).logoUrl" alt="" />
                        </router-link>

                      </div>
                    </div>
                  </div>
                </div>
                <!-- User Stats -->
                <div
                  class="border-t dark:border-true-gray-700 border-true-gray-100 bg-black-300 grid grid-cols-1 divide-y dark:divide-true-gray-700 divide-true-gray-100 sm:grid-cols-3 sm:divide-y-0 sm:divide-x">
                  <div
                    class="px-6 py-5 text-warm-gray-800 dark:text-warm-gray-200 text-md font-medium text-center flex flex-inline items-center justify-center space-x-1">
                    <PresentationChartLineIcon class="w-5 h-5" />
                    <span class="text-warm-gray-800 dark:text-warm-gray-200">{{ (user.rank) ? this.getOrdinal(user.rank) :
                      '' }}</span>
                    <span class="sm:hidden dark:text-warm-gray-400 text-warm-gray-700">Overall Rank</span>
                  </div>
                  <div
                    class="px-6 py-5 text-warm-gray-800 dark:text-warm-gray-200 text-md font-medium text-center flex flex-inline items-center justify-center space-x-1">
                    <Logo class="w-5 h-5" />
                    <span class="text-warm-gray-800 dark:text-warm-gray-200">{{ user.skillpoints }}</span>
                    <span class="sm:hidden dark:text-warm-gray-400 text-warm-gray-700">Skillpoints</span>
                  </div>
                  <div
                    class="px-6 py-5 text-warm-gray-800 dark:text-warm-gray-200 text-md font-medium text-center flex flex-inline items-center justify-center space-x-1">
                    <PaperAirplaneIcon class="w-5 h-5" />
                    <span class="text-warm-gray-800 dark:text-warm-gray-200">{{ (user.achievements) ?
                      user.achievements.length : '' }}</span>
                    <span class="sm:hidden dark:text-warm-gray-400 text-warm-gray-700">Achievements</span>
                  </div>
                </div>
              </div>
            </section>

            <!-- Exam Tracker Section -->
            <!-- <section aria-labelledby="exam-tracker">
              <ExamTracker />
            </section> -->

            <!-- Leaderboard -->
            <section aria-labelledby="leaderboard">
              <div v-if="isLoading"
                class="rounded-lg dark:bg-true-gray-800 bg-white overflow-hidden shadow h-96 sm:h-62 animate-pulse">
              </div>
              <div v-else class="rounded-lg dark:bg-true-gray-800 bg-white overflow-hidden shadow">
                <div class="p-6">
                  <h2 class="text-base font-bold dark:text-warm-gray-300 text-warm-gray-700" id="announcements-title">🏆
                    Overall Ranking</h2>
                  <div class="flow-root mt-6">
                    <ul class="-my-5 divide-y divide-warm-gray-200 dark:divide-warm-gray-600">
                      <li v-for="person in leaderboard.slice(0, 5)" :key="person.id" class="py-4">
                        <div class="flex items-center space-x-4">
                          <div class="flex-shrink-0">

                            <img v-if="person.photo" class="object-cover mx-auto h-8 w-8 rounded-full" :src="person.photo" alt="" />
                            <DefaultUser class="block mx-auto h-8 w-8" v-else />

                            <!-- <img v-if="person.imageUrl" class="h-8 w-8 rounded-full" :src="person.imageUrl" alt="" />
                            <DefaultUser v-else class="block mx-auto h-8 w-8" /> -->
                          </div>
                          <div class="flex-1 min-w-0">
                            <p class="text-sm font-medium dark:text-warm-gray-400 text-warm-gray-500 truncate">
                              {{ getOrdinal(person.rank) }} - {{ person.firstname }} {{ person.lastname }}
                            </p>
                            <p class="text-sm text-warm-gray-500">
                              {{ getSchoolById(schools, person.school).shorthand }} - {{ person.course.substr(-4, 3) }}
                            </p>
                            <!-- <p class="text-sm text-warm-gray-500">
                              {{ person.course.substr(-4, 3) }}
                            </p> -->
                          </div>
                          <div>
                            <a :href="person.href"
                              class="inline-flex items-center shadow-sm px-2.5 py-0.5 text-sm leading-5 font-medium rounded-full text-true-gray-700 bg-yellow-400 hover:bg-yellow-500">
                              <Logo class="w-3.5 h-3.5 mr-1" />
                              {{ person.skillpoints }}
                            </a>
                          </div>
                        </div>

                      </li>
                    </ul>
                  </div>
                  <div class="mt-6">
                    <router-link :to="'/leaderboard'"
                      class="flex justify-center items-center px-4 py-2 border border-true-gray-300 dark:border-true-gray-600 shadow-sm text-sm font-medium rounded-md dark:text-warm-gray-300 text-warm-gray-600 dark:bg-true-gray-700 dark:hover:bg-true-gray-600 hover:bg-true-gray-100 bg-true-gray-50">
                      View Leaderboard
                    </router-link>
                  </div>
                </div>
              </div>
            </section>

            <!-- Blogs -->
            <!-- <section aria-labelledby="blogs">
              <div class="rounded-lg dark:bg-true-gray-800 bg-white overflow-hidden shadow">
                <div class="p-6">
                  <h2 class="text-base font-bold dark:text-warm-gray-300 text-warm-gray-700" id="blogs-headline">Start Learning Here</h2>
                  <div class="flow-root mt-6">
                    <ul class="-my-5 divide-y divide-warm-gray-100 dark:divide-warm-gray-700">
                      <li v-for="blog in blogs" :key="blog.id" class="py-5">
                        <div class="relative focus-within:ring-2 focus-within:ring-yellow-500">
                          <h3 class="text-sm font-semibold dark:text-warm-gray-400 text-warm-gray-700">
                            <a :href="blog.href" target="_blank" class="hover:underline focus:outline-none ">
                              <span class="absolute inset-0" aria-hidden="true" />
                              {{ blog.title }}
                            </a>
                          </h3>
                          <p class="mt-1 text-sm text-warm-gray-500 line-clamp-2">
                            {{ blog.preview }}
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="mt-6">
                    <a href="https://blog.skilltech.ph/" target="_blank" class="flex justify-center items-center px-4 py-2 border border-true-gray-300 dark:border-true-gray-600 shadow-sm text-sm font-medium rounded-md dark:text-warm-gray-300 text-warm-gray-600 dark:bg-true-gray-700 dark:hover:bg-true-gray-600 hover:bg-true-gray-100 bg-true-gray-50">
                      Explore
                    </a>
                  </div>
                </div>
              </div>
            </section> -->


            <!-- What's New -->
            <section aria-labelledby="blogs" class="hidden sm:block">
              <div class="rounded-lg dark:bg-true-gray-800 bg-white overflow-hidden shadow">
                <div class="p-6">
                  <div class="space-y-1">
                    <h2 class="text-base font-bold dark:text-warm-gray-300 text-warm-gray-700" id="blogs-headline">What's
                      New in {{ versions[0].name }}</h2>
                    <span class="text-xs dark:text-warm-gray-400 text-warm-gray-700">Released
                      {{ versions[0].released }}</span>
                  </div>
                  <div class="flow-root mt-4">
                    <ul>
                      <li v-for="change in versions[0].changes" :key="change"
                        class="py-1 list-disc dark:text-warm-gray-500 text-warm-gray-600 ml-6 relative focus-within:ring-2 focus-within:ring-yellow-500 text-sm">
                        {{ change }}
                      </li>
                    </ul>
                  </div>
                  <div class="mt-6">
                    <router-link to="/changelog"
                      class="flex justify-center items-center px-4 py-2 border border-true-gray-300 dark:border-true-gray-600 shadow-sm text-sm font-medium rounded-md dark:text-warm-gray-300 text-warm-gray-600 dark:bg-true-gray-700 dark:hover:bg-true-gray-600 hover:bg-true-gray-100 bg-true-gray-50">
                      See All Updates
                    </router-link>
                  </div>
                </div>
              </div>
            </section>

          </div>
          <!-- Left column -->
          <div class="grid grid-cols-1 gap-4 lg:col-span-2">

            <!-- CAAP Card-->
            <div class="relative pt-48 pb-10 rounded-xl shadow overflow-hidden">
              <img class="absolute inset-0 h-full w-full object-cover"
                :src="aBoxImage1"
                alt="" />
              <div class="absolute inset-0 bg-yellow-400 mix-blend-multiply" />
              <div class="absolute inset-0 bg-gradient-to-t from-yellow-500 via-yellow-500 opacity-60" />
              <div class="relative px-8">
                <header>
                  <p
                    class="text-base font-semibold text-yellow-100 absolute -top-40 flex flex-inline items-center space-x-2">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
                      stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <span>Today is {{ this.moment().format('MMMM Do, YYYY') }}</span>
                  </p>
                </header>
                <div>
                  <h1 class="border-l-8 border-white pl-4 text-4xl max-w-md font-extrabold text-white">
                    Ready to unlock your Achievement Box?
                  </h1>

                </div>
                <blockquote class="mt-8">
                  <div class="relative text-lg font-medium text-yellow-50 md:flex-grow">
                    <div class="relative max-w-2xl">
                      Your input is essential! Complete your Exam Score Card and share your experience to claim your Achievement Box. It’s an important part of your learning journey and our community's growth.
                    </div>
                  </div>

                  <footer class="mt-4 flex flex-col sm:flex-row sm:inline-flex space-y-2 sm:space-y-0 sm:space-x-4">
                    
                    <router-link v-if="!isCaapPurchased" 
                      tag="a"
                      to="/reviewers"
                      class="flex justify-center items-center px-4 py-2 border border-true-gray-600 shadow-sm text-sm font-medium rounded-md text-warm-gray-300 bg-true-gray-700 hover:bg-true-gray-600">
                      Purchase Reviewer
                    </router-link>
                    <router-link v-else 
                      to="/caap"
                      tag="a"
                      class="flex justify-center items-center px-4 py-2 border border-true-gray-600 shadow-sm text-sm font-medium rounded-md text-warm-gray-300 bg-true-gray-700 hover:bg-true-gray-600">
                      Start Review
                    </router-link>

                    <router-link v-if="isCaapPurchased" tag="a" to="/reward" class="flex justify-center items-center px-4 py-2 border border-true-gray-600 shadow-sm text-sm font-medium rounded-md text-warm-gray-300 bg-true-gray-700 hover:bg-true-gray-600">
                      View My Score Card
                    </router-link>

                  </footer>
                </blockquote>
              </div>
            </div>

            <!-- Actions panel -->
            <section aria-labelledby="quick-links" class="mt-2">
              <div
                class="rounded-lg dark:bg-true-gray-700 bg-true-gray-100 overflow-hidden shadow divide-y divide-true-gray-100 dark:divide-warm-gray-700 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px">
                <h2 class="sr-only" id="quick-links-title">Quick links</h2>
                <div v-for="(action, actionIdx) in actions" :key="action.name"
                  :class="[actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '', actionIdx === 1 ? 'sm:rounded-tr-lg' : '', actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '', actionIdx === actions.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '', 'relative group bg-white dark:bg-true-gray-800 p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-cyan-500']">
                  <div>
                    <span :class="[action.iconBackground, action.iconForeground, 'rounded-lg inline-flex p-3']">
                      <img :src="action.img" class="w-8">
                    </span>
                  </div>
                  <div class="mt-4">
                    <h3 class="text-lg font-medium dark:text-warm-gray-300 text-warm-gray-700">
                      <button v-if="action.id == 'resume-practice'" @click="redirectToRecentPractice()"
                        class="focus:outline-none">
                        <span class="absolute inset-0" aria-hidden="true" />
                        {{ action.name }}
                      </button>
                      <button v-else-if="action.id == 'caap-history'" @click="redirectToCaapHistory()"
                        class="focus:outline-none">
                        <span class="absolute inset-0" aria-hidden="true" />
                        {{ action.name }}
                      </button>
                      <button href="#" v-else-if="action.id == 'caap-achievements'" @click="redirectToCaapAchievements()"
                        class="focus:outline-none">
                        <span class="absolute inset-0" aria-hidden="true" />
                        {{ action.name }}
                      </button>
                      <a v-else :href="action.href" :target="(action.isNewTab) ? '_blank' : '_self'"
                        class="focus:outline-none">
                        <!-- Extend touch target to entire panel -->
                        <span class="absolute inset-0" aria-hidden="true" />
                        {{ action.name }}
                      </a>
                    </h3>
                    <p class="mt-2 text-sm dark:text-warm-gray-500 text-warm-gray-500">
                      {{ action.description }}
                    </p>
                  </div>
                  <span
                    class="pointer-events-none absolute top-6 right-6 text-warm-gray-600 group-hover:text-warm-gray-500"
                    aria-hidden="true">
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                      <path
                        d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                    </svg>
                  </span>
                </div>
              </div>
            </section>

            <!-- CAAP Starter Kit -->
            <div class="mt-1 max-w-lg mx-auto grid gap-5 lg:grid-cols-2 lg:max-w-none">
              <div v-for="post in posts" :key="post.title" class="flex flex-col rounded-lg shadow overflow-hidden">
                <!-- <div class="flex-shrink-0">
                  <img class="h-48 w-full object-cover" :src="post.imageUrl" alt="" />
                </div> -->
                <div class="relative pt-48 pb-10 shadow overflow-hidden">
                  <img class="absolute inset-0 h-full w-full object-cover" :src="post.imageUrl" alt="" />
                  <div class="absolute inset-0 bg-yellow-500 mix-blend-multiply" />
                  <div class="absolute inset-0 bg-gradient-to-t from-yellow-400 via-yellow-500 opacity-25" />
                </div>
                <div class="flex-1 dark:bg-true-gray-800 bg-white p-6 flex flex-col justify-between">
                  <div class="flex-1">
                    <p class="text-sm font-medium text-yellow-400">
                      <a :href="post.category.href" target="_blank" class="hover:underline">
                        {{ post.category.name }}
                      </a>
                    </p>
                    <a :href="post.href" target="_blank" class="block mt-2">
                      <h3 class="text-lg font-medium dark:text-warm-gray-300 text-warm-gray-700">
                        <!-- <p class="text-xl font-semibold dark:text-warm-gray-200 text-warm-gray-900"> -->
                        {{ post.title }}
                      </h3>
                      <p class="mt-3 text-sm dark:text-warm-gray-500 text-warm-gray-500">
                        {{ post.description }}
                      </p>
                    </a>
                  </div>
                  <!-- <div class="mt-6 flex items-center">
                    <div class="flex-shrink-0">
                      <a :href="post.author.href">
                        <span class="sr-only">{{ post.author.name }}</span>
                        <img class="h-10 w-10 rounded-full" :src="post.author.imageUrl" alt="" />
                      </a>
                    </div>
                    <div class="ml-3">
                      <p class="text-sm font-medium text-gray-900">
                        <a :href="post.author.href" class="hover:underline">
                          {{ post.author.name }}
                        </a>
                      </p>
                      <div class="flex space-x-1 text-sm text-gray-500">
                        <time :datetime="post.datetime">
                          {{ post.date }}
                        </time>
                        <span aria-hidden="true"> &middot; </span>
                        <span> {{ post.readingTime }} read </span>
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>




          </div>


        </div>
      </div>
    </main>

    <!-- Toast Notification -->
    <ToastNotification :isToastNotifOpen="isToastNotifOpen" :toast="toast" @closeToastNotif="closeToastNotif()" />

    <!-- <ToastNotification1 
      :isToastNotifOpen="isToastNotif1Open"
      :toast="toast"
      @closeToastNotif="closeToastNotif1()"
    /> -->

    <!-- <ToastNotification2 
      :isToastNotifOpen="isToastNotif2Open"
      :toast="toast"
      @closeToastNotif="closeToastNotif2()"
    /> -->

  </div>
</template>

<script>

import firebase from "@/firebase/config";
import "firebase/auth";
import "firebase/firestore";
const db = firebase.firestore();

import { ref } from 'vue';
import ROUTER from '@/router';

import AllPasserLogo from "@/components/AllPasserLogo"
import AllSpeedrunnerLogo from "@/components/AllSpeedrunnerLogo"
import AllMasterLogo from "@/components/AllMasterLogo"
import AllPasserLogoHeli from "@/components/AllPasserLogoHeli"
import AllSpeedrunnerLogoHeli from "@/components/AllSpeedrunnerLogoHeli"
import AllMasterLogoHeli from "@/components/AllMasterLogoHeli"
import ExamTracker from "@/components/ExamTracker"
import { PresentationChartLineIcon, PencilAltIcon, PaperAirplaneIcon } from '@heroicons/vue/outline'
import { BadgeCheckIcon } from '@heroicons/vue/solid'

import Logo from "@/components/Logo"
import DefaultUser from "@/components/DefaultUser"
import ToastNotification from "@/components/notification/ToastNotification"
import LoadingScreen from "@/components/appshell/LoadingScreen"

import { homeData } from "@/data/home.data"
import { changelogData } from "@/data/changelog.data"
import { leaderboardService } from "@/utils/leaderboard"
import { userService } from "@/utils/user"
import { schoolService } from "@/utils/school"


import aBoxImage1 from '@/assets/images/achievement-box-feat-1.jpeg'
import moment from "moment";


const posts = [
  {
    title: 'This is Why You\'ll Fail Your CAAP License Exam ',
    href: 'https://blog.skilltech.ph/this-is-why-youll-fail-your-caap-license-exam/',
    category: { name: 'CAAP License Tips', href: 'https://blog.skilltech.ph/caap-license-tips/' },
    description:
      'Take a look at the most common reasons why AMT and AVT new grads fail the CAAP Knowledge Test. Avoid making the same mistakes again and finish your exams like a boss. ',
    date: 'Feb 12, 2020',
    datetime: '2020-02-12',
    imageUrl:
      'https://blog.skilltech.ph/images/uploads/mtp-hard.jpg',
    readingTime: '11 min',
    author: {
      name: 'Daniela Metz',
      href: '#',
      imageUrl:
        'https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
  {
    title: 'Top 10 Expectations vs Reality for AMT New Grads',
    href: 'https://blog.skilltech.ph/top-10-expectations-vs-reality-for-amt-new-grads/',
    category: { name: 'Road to AMT', href: 'https://blog.skilltech.ph/road-to-amt/' },
    description:
      'A compilation of eye-opening facts about entering the aviation industry. Learn the most common misconceptions that new graduates have about their chosen fields and be ready for the real world. ',
    date: 'Feb 12, 2020',
    datetime: '2020-02-12',
    imageUrl:
      'https://blog.skilltech.ph/images/uploads/expvsreal.jpg',
    readingTime: '11 min',
    author: {
      name: 'Daniela Metz',
      href: '#',
      imageUrl:
        'https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
  {
    title: 'Top 10 Aircraft Maintenance Facts Exposed ',
    href: 'https://blog.skilltech.ph/top-10-aircraft-maintenance-facts-exposed/',
    category: { name: 'Road to AMT', href: 'https://blog.skilltech.ph/road-to-amt/' },
    description:
      ' A list of everyday working conditions experienced by Aircraft Mechanics that students should be aware of in advance. Learn important realities that are not often mentioned in your books. ',
    date: 'Mar 16, 2020',
    datetime: '2020-03-16',
    imageUrl:
      'https://blog.skilltech.ph/images/uploads/exposed.jpg',
    readingTime: '6 min',
    author: {
      name: 'Roel Aufderehar',
      href: '#',
      imageUrl:
        'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
  {
    title: 'How to Create Resumes for Aircraft Mechanics ',
    href: 'https://blog.skilltech.ph/how-to-create-resumes-for-aircraft-mechanics/',
    category: { name: 'AMT Career Starters', href: 'https://blog.skilltech.ph/amt-career-starters/' },
    description:
      'An easy-to-follow resumé building guide for your next aviation job. With tips and techniques especially formulated for future Aircraft Mechanics and other aviation professionals. ',
    date: 'Mar 10, 2020',
    datetime: '2020-03-10',
    imageUrl:
      'https://blog.skilltech.ph/images/uploads/resume.jpg',
    readingTime: '4 min',
    author: {
      name: 'Brenna Goyette',
      href: '#',
      imageUrl:
        'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
]

export default {
  components: {
    // Profile Icons
    PencilAltIcon,
    PresentationChartLineIcon,
    BadgeCheckIcon, PaperAirplaneIcon,
    Logo,
    AllPasserLogo, AllSpeedrunnerLogo, AllMasterLogo,
    AllPasserLogoHeli, AllSpeedrunnerLogoHeli, AllMasterLogoHeli,
    DefaultUser,
    ToastNotification,
    LoadingScreen,
    ExamTracker
  },
  data() {
    return {
      // Dashboard Clickables
      actions: homeData.actions,
      blogs: homeData.blogs,
      // booleans
      isLoading: true,
      isCaapPurchased: false,
      // toast notification
      toast: {
        headline: null,
        message: null,
        type: null
      },
      user: {}, // user data
      leaderboard: [],  // leaderboard data
      practice: null,  // the route to the open practice
      versions: changelogData.versions,
      moment: moment,
      posts: posts,
      aBoxImage1: aBoxImage1
    }
  },
  setup() {
    const isToastNotifOpen = ref(false);
    return {
      isToastNotifOpen,
      openToastNotif() { isToastNotifOpen.value = true },
      closeToastNotif() { isToastNotifOpen.value = false },
    }
  },
  methods: {

    getSchoolById: schoolService.getSchoolById,

    /**
     * Get the ordinal of a number for ranking system
     * i.e. getOrdinal(1) returns String "1st"
     * @NOTE: Duplicate over /leaderboard
     */
    getOrdinal(i) {
      var j = i % 10, k = i % 100;
      if (j == 1 && k != 11) { return i + "st"; }
      if (j == 2 && k != 12) { return i + "nd"; }
      if (j == 3 && k != 13) { return i + "rd"; }
      return i + "th";
    },

    /**
     * Redirect the user if they have a most recent open practice.
     * If user doesn't have an open practice, send notification.
     * If user did not purchase reviewer, send notification.
     * If user has an open practice, redirect to practice page to continue.
     */
    redirectToRecentPractice() {
      if (this.practice == null) {
        if (this.isCaapPurchased) {
          this.toast.headline = "All Good!"
          this.toast.message = "You have no open practice exams. Keep going and start practicing!"
          this.toast.type = "SUCCESS"
        } else {
          this.toast.headline = "Purchase A Reviewer!"
          this.toast.message = "You can purchase a reviewer in the Reviewer section and start practicing."
          this.toast.type = "WARN"
        }
        this.openToastNotif()
      } else {
        console.log(this.$router);
        // this.$router.push({path: "/caap/course/amt/nat-sci/basic-math/practice", query: {id: "5dxHwP5dZeCos6hy8Wq1"}}) // redirect
        this.$router.push(this.practice) // redirect
      }
    },

    /**
     * Redirect the user to CAAP History.
     * If user did not purchase CAAP yet, send notification.
     * Else, redirect user has an open practice, redirect to practice page to continue.
     */
    redirectToCaapHistory() {
      if (!this.isCaapPurchased) {
        this.toast.headline = "Purchase the CAAP Reviewer!"
        this.toast.message = "You can purchase the CAAP reviewer in the Reviewer section to see your mock exam history."
        this.toast.type = "WARN"
        this.openToastNotif();
      } else {
        this.$router.push({ path: '/caap/history' });
      }
    },

    /**
     * Redirect the user to CAAP Achievements.
     * If user did not purchase CAAP yet, send notification.
     * Else, redirect user has an open practice, redirect to practice page to continue.
     */
    redirectToCaapAchievements() {
      if (!this.isCaapPurchased) {
        this.toast.headline = "Purchase the CAAP Reviewer!"
        this.toast.message = "You can purchase the CAAP reviewer in the Reviewer section to see unlockable achievements."
        this.toast.type = "WARN"
        this.openToastNotif()
      } else {
        this.$router.push({ path: `/caap/achievements` });
      }
    },

    /**
     * NOTE: Connects To Firebase
     * fetch the most recent practice
     */
    fetchMostRecentPractice() {
      return db.collection("practice").where("user", "==", firebase.auth().currentUser.uid).where("status", "==", "started").orderBy("time.start", "desc").limit(1).get()
        .then((qs) => {
          if (qs.size > 0) {
            let data;
            qs.forEach((doc) => {
              data = { path: doc.data().url, query: { id: doc.id } }
            });
            return data;
          } else {
            // console.log("User has no open practice exam!")
            return null
          }
        })
        .catch((error) => {
          console.error("Error getting practice document => ", error);
          throw `Error getting document: ${error}`
        })
    },
    getExamDate(date) {

      // this.moment.unix(user.license.exam_date/1000).fromNow() }}</strong>.

      // var dateText = moment.unix(date).from(new Date());
      var startOfToday = moment().startOf('day');
      var startOfDate = moment.unix(date).startOf('day');
      var daysDiff = startOfDate.diff(startOfToday, 'days');
      var days = {
        '0': 'today',
        '-1': 'yesterday',
        '1': 'tomorrow'
      };

      if (Math.abs(daysDiff) <= 1) {
        return days[daysDiff];
      } else {
        return moment.unix(date).add(1, "days").fromNow()
      }
    },

    // [END OF METHODS]
  },
  async created() {
    this.isLoading = true

    /* CLAIMS */
    let claims = await userService.fetchUserClaims(firebase.auth())
    if (!claims.isOnboarded) {
      this.$router.push({ path: '/onboarding' })  // reroute non-onboarded users
    } else {

      this.isCaapPurchased = (claims.caap) ? true : false;  // store isCaapPurchased claims
      // console.log(this.isCaapPurchased)

      /* USER */
      this.user = await userService.fetchUserData(firebase.firestore(), firebase.auth().currentUser.uid);
      if (!this.user.license || !this.user.license.exam_date) {
        this.$router.push({ path: '/onboarding-caap' });
      } else {
        /* LEADERBOARD / RANK */
        this.leaderboard = await leaderboardService.fetchLeaderboard(firebase.firestore());
        this.leaderboard = leaderboardService.sortLeaderboard(this.leaderboard) // sort the leaderboard


        // console.log(this.leaderboard)

        // set the rank of the current logged in user
        let i = this.leaderboard.findIndex(e => e.id == this.user.id)
        this.user.rank = this.leaderboard[i].rank

        /* RECENT PRACTICE */
        if (this.isCaapPurchased) this.practice = await this.fetchMostRecentPractice()
        //console.log(this.practice)


        /* SCHOOLS */
        // console.log(this.schools)
        this.schools = await schoolService.fetchAllSchools(firebase.firestore())
        this.schools = schoolService.sortSchoolsAlpha(this.schools)

        // console.log("Adding overflow-hidden")
        document.body.classList.add("overflow-hidden");

        // TIMER
        // var eventTime= this.moment(this.user.license/1000); // Timestamp - Sun, 21 Apr 2013 13:00:00 GMT
        // var currentTime = moment(); // Timestamp - Sun, 21 Apr 2013 12:30:00 GMT
        // var diffTime = eventTime - currentTime;
        // var duration = moment.duration(diffTime*1000, 'milliseconds');
        // var interval = 1000;

        // setInterval(function(){
        //   duration = moment.duration(duration - interval, 'milliseconds');
        //   console.log(eventTime)
        //   console.log(currentTime)
        //   console.log(duration.days() + ":"+duration.hours() + ":" + duration.minutes() + ":" + duration.seconds())
        // }, interval);

        /** Season 2 */

        this.isLoading = false
      }


    }

  }
}
</script>