<script setup>

import { onMounted, ref, watch } from 'vue';
import ROUTER from "@/router";
import firebase from "@/firebase/config";
import "firebase/auth";
import "firebase/firestore";
import { userService } from "@/utils/user";
import caapKnowledge from '@/assets/images/caap-primary-photo.jpeg';
import caapPractical from '@/assets/images/plus-primary-photo.jpeg';
import caap101 from '@/assets/images/lite-primary-photo.jpeg';
import entranceExam from '@/assets/images/mtp-primary-photo.jpeg';

import store from '@/store'

import PurchaseModal from "@/components/modals/PurchaseModalUpdated";
import PurchaseMtpModal from "@/modules/mtp/components/PurchaseMtpModal";
import PurchasePlusModal from "@/modules/plus/components/PurchasePlusModal";

import ActivateModal from "@/components/modals/ActivateModalUpdated";
import ActivatePlusModal from "@/modules/plus/components/ActivatePlusModal";
import ActivateMtpModal from "@/modules/mtp/components/ActivateMtpModal";

import { formatDate, formatDateInString } from '@/utils/dateHelper.js'

import { updateUserClaimBlockUser, updateUserInfoSetBlock, addUserToBlock } from "@/utils/helper";

const db = firebase.firestore();

const isWaiting = ref(true);
const isChecking = ref(false); // flag if the UI is checking the button

const user = ref(null);
const userClaims = ref(null);

const isPurchaseModalOpen = ref(false);
const isPurchaseMtpModalOpen = ref(false);
const isPurchasePlusModalOpen = ref(false);

const isActivateModalOpen = ref(false);
const isActivatePlusModalOpen = ref(false);
const isActivateMtpModalOpen = ref(false);

const isActivating = ref(false);

const reviewers = ref([
  {
    id: 0,
    name: 'CAAP 101',
    price: null,
    imageSrc: caap101,
    isBeta: true,
    url: '/caap-101',
    claimTag: '',
    description: `Everything you need to know about CAAP and the CAAP Licensure Exam. Learn about the exam requirements, how to book an exam date, how to get there, etc. Bonus subject on how to effectively study for the exam and pass in no time!`,
  },
  {
    id: 1,
    name: 'CAAP Knowledge',
    prices: [
      {
        amount: 3999,
        duration: 90,
        durationInWords: '3 months',
      },
      {
        amount: 5999,
        duration: 180,
        durationInWords: '6 months',
      },
    ],
    imageSrc: caapKnowledge,
    isBeta: false,
    url: '/caap',
    claimTag: 'caap',
    description: `Review for your Civil Aviation Authority of the Philippines (CAAP) AMT or AVT licensure exam. Complete coverage for all Knowledge Test subjects in practice or mock exam modes.`,
  },
  {
    id: 2,
    name: 'CAAP Practical',
    prices: [
      { amount: 999, duration: 90, durationInWords: '3 months' },
    ],
    description: `Become more ready on your CAAP Skill Test by learning aircraft maintenance basic principles, terminologies, toolings, aircraft parts, and more by using this supplementary reviewer.`,
    imageSrc: caapPractical,
    isBeta: true,
    url: '/plus',
    claimTag: 'caap_plus',
  },
  {
    id: 3,
    name: 'Entrance Exam',
    prices: [
      { amount: 999, duration: 90, durationInWords: '3 months' },
    ],
    description: `Prepare for the latest topics used by aviation companies today for their Maintenance Training Program (MTP) entrance exams. Improve your exam skills with categories like Abstract Reasoning and more.`,
    imageSrc: entranceExam,
    isBeta: true,
    url: '/mtp',
    claimTag: 'mtp',
  },
  {
    id: 4,
    name: 'CAAP Lite',
    prices: [
      { amount: 2999, duration: 90, durationInWords: '3 months' },
    ],
    description: `For airmen who got their CAAP licenses expired, get it easily activated again by passing the exam using this specially designed reviewer. Covers topics that are essential for license renewal only.`,
    imageSrc: entranceExam,
    isBeta: true,
    url: '/lite',
    claimTag: 'caap_lite',
  },
]);


watch(user, (newVal, oldVal) => {

  console.log('User data key change! reinitialize UI');
  initializeUI();

}, { deep: true })

onMounted(async () => {
  
  userClaims.value = await userService.fetchUserClaims(firebase.auth())
  await fetchUserInfo();
  initializeUI()

})

/**
 * Get the user data or information form database. We will check
 * each subscription.
 * Did not inlcude the fetching of claims here cause only the data from database keep changing in this page.
 * 
 */ 
const fetchUserInfo = async () => {
  // fetch the user information from db
  let userInfo = await userService.fetchUserData( firebase.firestore(), firebase.auth().currentUser.uid);
  // update the user info in store also
  await store.dispatch('setUserInfoOnly', { info: userInfo });
  // now, use the user object from store so that every time there is changes it will change the user in store also
  user.value = store.getters.user.info;
}

const initializeUI = async () => {


  /**
   * We will go over each reviewer and check whether the user has acess to it
   * via their claims. If yes, we then fetch their data.
   * 
   * Make a copy of the original reviewers data.
   */
  let _reviewers = reviewers.value;
  _reviewers.forEach((reviewer, index) => {

    let _userData = {
      subscriptionStatus: null,
      refCode: null,
      hasPreviousSubscription: null,
      expirationDate: null,
      durationInWords: null
    }
    /**
     * Check if the user has a subscription. If yes,
     * then check whether it's confirmed or not.
     * Update needed user data
     */
    _userData.subscriptionStatus = user.value.reviewers[reviewer.claimTag] !== undefined ? user.value.reviewers[reviewer.claimTag].toUpperCase() : 'INACTIVE';
    _userData.refCode = user.value[`${reviewer.claimTag}_data`] && user.value[`${reviewer.claimTag}_data`].gcash_refno ? user.value[`${reviewer.claimTag}_data`].gcash_refno : null;
    _userData.expirationDate = user.value[`${reviewer.claimTag}_data`] && user.value[`${reviewer.claimTag}_data`].expiration_date ? formatDate(user.value[`${reviewer.claimTag}_data`].expiration_date.seconds) : null;
    _userData.duration = user.value[`${reviewer.claimTag}_data`] && user.value[`${reviewer.claimTag}_data`].duration ? user.value[`${reviewer.claimTag}_data`].duration : null;
    _userData.durationInWords = user.value[`${reviewer.claimTag}_data`] && user.value[`${reviewer.claimTag}_data`].durationInWords ? user.value[`${reviewer.claimTag}_data`].durationInWords : null;

    /**
     * Check if user has previous subscription for this reviewer
     */
    if (reviewer.claimTag == 'caap') {
      _userData.hasPreviousSubscription = user.value.isCaapBlocked;
    } else if (reviewer.claimTag == 'caap_plus') {
      _userData.hasPreviousSubscription = user.value.isPlusBlocked;
    } else if (reviewer.claimTag == 'mtp') {
      _userData.hasPreviousSubscription = user.value.isMtpBlocked;
    }

    // transform status to WAITING if status is equal to PAID
    _userData.subscriptionStatus = _userData.subscriptionStatus === 'PAID' ? 'WAITING' : _userData.subscriptionStatus;
    // update the the userData for this reviewer
    _reviewers[index].userData = _userData;
  });


  /**
   * Do a special check for CAAP 101. Check if the user has ACTIVE subscriptions
   * for Knowledge (reviewers[1]) and/or Practical (reviewers[2]).
   */
  if (_reviewers[1].userData.subscriptionStatus != 'INACTIVE' || _reviewers[2].userData.subscriptionStatus != 'INACTIVE') {
    _reviewers[0].userData.subscriptionStatus = 'ACTIVE'
  }

  /**
   * Do a special check for CAAP Lite. If user has a subscription, then show an expiration date.
   */
  let liteIndex = _reviewers.findIndex(reviewer => reviewer.claimTag === 'caap_lite');
  if (liteIndex > -1) {
    if (_reviewers[liteIndex] && _reviewers[liteIndex].userData.subscriptionStatus == 'ACTIVE') {
      _reviewers[liteIndex].userData.expirationDate = 'December 30, 2024'
    } else {
      _reviewers.splice(liteIndex, 1);
    }
  }

  /**
   * Replace the reviewers ref with the updated one.
   */
  reviewers.value = _reviewers;
  isWaiting.value = false;
}

/**
 * Click handlers
 */

const handlePurchaseClick = (reviewer_key) => {
  switch (reviewer_key) {
    case 'caap':
      isPurchaseModalOpen.value = true;
      break;

    case 'caap_plus':
      isPurchasePlusModalOpen.value = true;
      break;

    case 'mtp':
      isPurchaseMtpModalOpen.value = true;
      break;

    default:
      break;
  }
}

const handleActivationClick = (reviewer_key) => {
  switch (reviewer_key) {
    case 'caap':
      isActivateModalOpen.value = true;
      break;

    case 'caap_plus':
      isActivatePlusModalOpen.value = true;
      break;

    case 'mtp':
      isActivateMtpModalOpen.value = true;
      break;

    default:
      break;
  }
}


/**
 * Activation methods
 */

const initiateActivation = async (key) => {

  isActivating.value = true;
  try {

    let result = await activateReviewerAccess(key)
    if (result) {

      alert(`Activation for complete. Good luck on your review!`)

      // refetch user data.
      await fetchUserInfo();

    }
  } catch (error) {
    console.error(error)
    alert(error)
  } finally {
    isActivating.value = false;
  }

}

const activateReviewerAccess = (key) => {

  return new Promise(async (resolve, reject) => {
    // Update users reviewers object and reviewers data
    let _data = null;
    let _rev_data = null;

    let _user_reviewers = { ...user.value.reviewers };

    switch (key) {
      case 'caap':

        _user_reviewers = {'reviewers.caap': 'active'};

        _data = (user.value.caap_data != null) ? user.value.caap_data : {};
        _data.activation_date = new Date();
        _data.expiration_date = getReviewerExpiry(_data.duration);
        _rev_data = {
          caap_data: _data
        };

        break;
      case 'caap_plus':

        _user_reviewers = {'reviewers.caap_plus': 'active'};

        _data = (user.value.caap_plus_data != null) ? user.value.caap_plus_data : {};
        _data.activation_date = new Date();
        _data.expiration_date = getReviewerExpiry(90);
        _rev_data = {
          caap_plus_data: _data
        };

        break;
      case 'mtp':
        _user_reviewers = {'reviewers.mtp': 'active'};

        _data = (user.value.mtp_data != null) ? user.value.mtp_data : {};
        _data.activation_date = new Date();
        _data.expiration_date = getReviewerExpiry(90);
        _rev_data = {
          mtp_data: _data
        };

        break;
      default:
          console.error('Invalid key passed!');
        return;
        break;
    }

    // Push to database
    db.collection("users").doc(user.value.id).update({
      ..._user_reviewers,
      ..._rev_data
    })
      .then(async () => {
        console.log(`Successfully activated ${key.toUpperCase()} for user: ${user.value.email}`);

        // Also update the claim to have access
        let claimActivationResult = await activateClaims(key)
        if (claimActivationResult) {
          resolve(true)
        }
      })
      .catch((error) => {
        console.error("Error updating user data: ", error)
        reject(error)
      });

  })
}

const activateClaims = async (key) => {

  return new Promise(async (resolve, reject) => {
    try {


      let expAt = null;
      let expDate = null;

      // switch (key) {
      //   case 'caap':
      //     expDate = getReviewerExpiry(user.value.caap_data.duration);
      //     expAt = { caapExpAt: expDate }
      //     break;
      //   case 'caap_plus':
      //     expDate = getReviewerExpiry();
      //     expAt = { plusExpAt: expDate }
      //     break;
      //   case 'mtp':
      //     expDate = getReviewerExpiry();
      //     expAt = { mtpExpAt: expDate }
      //     break;
      //   default:
      //     console.error('Invalid key passed!');
      //     return;
      //     break;
      // }

      // const activateReviewerFbFunc = firebase.functions().httpsCallable("activateReviewer");
      // let activateResponse = await activateReviewerFbFunc({ email: user.value.email, ...expAt })


      let funcPayload = null;
      switch (key) {
        case 'caap':
          expDate = getReviewerExpiry(user.value.caap_data.duration);
          funcPayload = { caap: true, caapExpAt: expDate }
          break;
        case 'caap_plus':
          expDate = getReviewerExpiry(90);
          funcPayload = { caap_plus: true, plusExpAt: expDate }
          break;
        case 'mtp':
          expDate = getReviewerExpiry(90);
          funcPayload = { mtp: true, mtpExpAt: expDate }
          break;
        default:
          console.error('Invalid key passed!');
          return;
          break;
      }
      const updateUserClaimsFbFunc = firebase.functions().httpsCallable("updateUserClaims");
      const activateResponse = await updateUserClaimsFbFunc({
        email: user.value.email,
        payload: funcPayload
      })
      console.log('response activating user claims. ===> ', activateResponse);
      resolve(activateResponse)

    } catch (error) {
      console.error('Error happened during claims activation.')
      reject(error)
    }
  })

}

const getReviewerExpiry = (days = 180) => {
  let durationInDays = days;
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + durationInDays);
  return currentDate;
}

/**
 * Click handlers
 */
const handleStartReviewClick = (reviewer) => {
  /**
   * Before the user goes to the review page, we have 
   * to check whether or not the user has an expired subscription.
   * 
   * Apart from the router's expiration guard, we have to do this
   * for the sake of updating the UI. Once we figure out the user
   * has an expired subscription, we redirect them back to the Reviewers
   * page and update the UI.
   * 
   * No need to redirect them to the reviewers page.
   */

  /**
   * 1. Evaluate which reviewer the user is accessing
   * 2. Get the expiry date from the claims
   * 3. Check if its expired
   * 4. If yes, update the UI and halt the redirect.
   * 5. If no, redirect user.
   */

  isChecking.value = true;

  switch (reviewer.claimTag) {
    case 'caap':
      if (checkIfExpired(userClaims.value.caapExpAt)) {
        console.warn("The user has an expired subscription for ", reviewer.claimTag)
        handleExpiredSubscription(reviewer.claimTag)
      }else{
        ROUTER.push({ path: `/caap` })
      }
      break;

    case 'caap_plus':
      if (checkIfExpired(userClaims.value.plusExpAt)) {
        console.warn("The user has an expired subscription for ", reviewer.claimTag)
        handleExpiredSubscription(reviewer.claimTag)
      }else{
        ROUTER.push({ path: `/plus` })
      }
      break;

    case 'mtp':
      if (checkIfExpired(userClaims.value.mtpExpAt)) {
        console.warn("The user has an expired subscription for ", reviewer.claimTag)
        handleExpiredSubscription(reviewer.claimTag)
      }else{
        ROUTER.push({ path: `/mtp` })
      }
      break;

    case 'caap_lite':
      ROUTER.push({ path: `/lite` })
      break;

    default:
      ROUTER.push({ path: `/caap-101` })
      break;
  }
}

function checkIfExpired(expiryDate) {
  const currentDate = new Date();
  const expirationDate = new Date(expiryDate);
  return currentDate > expirationDate;
}

/**
 * This function will handle any expired subscription.
 */
const handleExpiredSubscription = async (reviewerTag) => {

  let userInfo = {
    id: user.value.id,
    email: user.value.email,
  }

  let promisesToExecute = [
    updateUserClaimBlockUser(userInfo.email, reviewerTag),
    updateUserInfoSetBlock(userInfo, reviewerTag),
  ];

  if (reviewerTag == 'caap') {
    promisesToExecute.push(addUserToBlock(userInfo))
  }

  await Promise.all(promisesToExecute);

  await fetchUserInfo()

  isChecking.value = false;

}

</script>
<template>
  <div v-if="!isWaiting" class="reviewers-container">
    <!-- Header -->
    <header class="header-container">
      <h1 class="header-title">
        Skilltech Reviewers 📚
      </h1>
      <p class="header-description">
        Get ahead with our latest aviation exam reviewers for all aspiring
        Aircraft Mechanics. Complete with features and pointers developed from actual exam
        standards and practices.
      </p>
    </header>
    <!--  -->
    <section class="list-container">
      <ul class="reviewers-list">
        <li v-for="reviewer in reviewers" :key="reviewer.id" class="reviewer-card relative">
          <div class="card-image">
            <img :src="reviewer.imageSrc" class="w-full h-full object-cover" alt="Reviewer Cover Photo" />
          </div>
          <div class="card-main">
            <!-- {{ reviewer.userData }} -->
            <div class="flex space-x-3">
              <h2 class="card-name">{{ reviewer.name }}</h2>
              <span v-if="reviewer.isBeta" class="badge--beta">
                BETA
              </span>
            </div>
            <p class="card-description">
              {{ reviewer.description }}
            </p>

            <!-- Reviewer's purchase information is displayed here -->
            <div v-if="reviewer.userData.subscriptionStatus == 'INACTIVE'" class="purchase-info">
              <div v-if="reviewer.name == 'CAAP 101'">
                <p><strong>Price:</strong> Free with any CAAP Reviewer (Knowledge or Practical)</p>
                <p><strong>Duration:</strong> Same as your active CAAP Reviewer</p>
              </div>
              <div v-else :class="[(reviewer.claimTag == 'caap') ? 'md:grid-cols-2' : '', 'grid gap-2']">
                <div v-for="price, i in reviewer.prices" class="rounded-md py-2">
                  <p><strong>Price:</strong> PHP {{ price.amount.toLocaleString() }}</p>
                  <p><strong>Duration:</strong> {{ price.durationInWords }} upon Activation</p>
                </div>
              </div>
            </div>

            <!-- Notice will be displayed here -->
            <div class="notice-container">
              <div v-if="reviewer.name == 'CAAP 101'"></div>
              <div
                v-else-if="reviewer.userData.subscriptionStatus == 'INACTIVE' && !reviewer.userData.hasPreviousSubscription"
                class="notice-base notice-base--warn">
                <p>You can buy the reviewer now and activate it later. The {{ reviewer.prices.length > 1 ? '3 or 6' :
    '3' }} months duration will only start once
                  activated. Click the purchase button below for payment instructions.</p>
                <p>
                  Have questions? Visit the FAQ page <router-link to="/faqs" class="link-base">here</router-link>.
                  <span v-if="reviewer.name == 'CAAP Knowledge'">Have a discount coupon? Click <router-link to="/coupon"
                      class="link-base">here</router-link>.</span>
                </p>
              </div>
              <div
                v-else-if="reviewer.userData.subscriptionStatus == 'INACTIVE' && reviewer.userData.hasPreviousSubscription"
                class="notice-base notice-base--warn">
                <p>Your subscription has expired. There are no free extensions; please subscribe
                  again if needed. Click the purchase button below for payment instructions.</p>
                <p v-if="reviewer.claimTag == 'caap'">Did you already took the exam and passed? Claim your FREE reward
                  <router-link to="/reward" class="link-base">here</router-link>.
                </p>
                <p>
                  Have questions? Visit the FAQ page <router-link to="/faqs" class="link-base">here</router-link>.
                  <span v-if="reviewer.name == 'CAAP Knowledge'">Have a discount coupon? Click <router-link to="/coupon"
                      class="link-base">here</router-link>.</span>
                </p>
              </div>
              <div v-else-if="reviewer.userData.subscriptionStatus == 'WAITING'" class="notice-base notice-base--info">
                <p>Your payment is currently being reviewed. It should be approved within 24 hours. <span
                    v-if="reviewer.name != 'Entrance Exam'">For now, you can
                    start reading the free <strong>CAAP 101</strong> reviewer.</span></p>
                <p>You submitted the following Ref. Code: <strong>{{ reviewer.userData.refCode }}</strong></p>
              </div>
              <div v-else-if="reviewer.userData.subscriptionStatus == 'READY'" class="notice-base notice-base--info">
                <p>Your reviewer can now be activated. Please be advised that the {{ reviewer.userData.durationInWords ?
    reviewer.userData.durationInWords : '6 Months' }} duration will only start
                  once
                  you activate your reviewer. You can activate it anytime you are ready.</p>
                <p>If you activate now, it will expire on <span class="font-bold underline">{{
    formatDateInString(getReviewerExpiry(reviewer.userData.duration ?
      reviewer.userData.duration : 180)) }}</span>.</p>
              </div>
              <div v-else-if="reviewer.userData.subscriptionStatus == 'ACTIVE'" class="notice-base notice-base--gray">
                <p>Your reviewer access will expire on <strong>{{ reviewer.userData.expirationDate }}</strong>.</p>
              </div>
            </div>

            <!-- Buttons will be displayed here -->
            <div class="button-container">
              <button v-if="reviewer.name == 'CAAP 101' && reviewer.userData.subscriptionStatus == 'INACTIVE'"
                class="button-base button-base--disabled">
                Free Upon CAAP Reviewer Purchase
              </button>
              <button v-else-if="reviewer.userData.subscriptionStatus == 'INACTIVE'"
                @click="handlePurchaseClick(reviewer.claimTag)" class="button-base button-base--active">
                Purchase Subscription
              </button>
              <button v-else-if="reviewer.userData.subscriptionStatus == 'WAITING'"
                class="button-base button-base--disabled" disabled>
                Waiting for Confirmation
              </button>
              <button v-else-if="reviewer.userData.subscriptionStatus == 'READY'" :disabled="isActivating"
                @click="handleActivationClick(reviewer.claimTag)"
                class="button-base button-base--ready disabled:opacity-25">
                {{ isActivating ? 'Please wait for activation...' : 'Activate Now' }}
              </button>
              <!-- <router-link v-else-if="reviewer.userData.subscriptionStatus == 'ACTIVE'" :to="reviewer.url"
                class="button-base button-base--active">
                Start Review
              </router-link> -->
              <button v-else-if="reviewer.userData.subscriptionStatus == 'ACTIVE'" type="button"
                :disabled="isChecking"
                @click="handleStartReviewClick(reviewer)" class="button-base button-base--active">
                Start Review
              </button>
            </div>


          </div>
        </li>
      </ul>
    </section>

    <!-- Purchase Modals -->

    <PurchaseModal :isPurchaseModalOpen="isPurchaseModalOpen" :user="user"
      @closePurchaseModal="isPurchaseModalOpen = false" />

    <PurchasePlusModal :isPurchaseModalOpen="isPurchasePlusModalOpen" :user="user"
      @closePurchaseModal="isPurchasePlusModalOpen = false" />

    <PurchaseMtpModal :isPurchaseModalOpen="isPurchaseMtpModalOpen" :user="user"
      @closePurchaseModal="isPurchaseMtpModalOpen = false" />

    <!-- Activate Modals -->
    <ActivateModal :isActivateModalOpen="isActivateModalOpen" @closeActivateModal="isActivateModalOpen = false"
      :user="user" @initiateActivation="initiateActivation('caap')" />

    <ActivatePlusModal :isActivatePlusModalOpen="isActivatePlusModalOpen"
      @closeActivatePlusModal="isActivatePlusModalOpen = false"
      @initiatePlusActivation="initiateActivation('caap_plus')" />

    <ActivateMtpModal :isActivateMtpModalOpen="isActivateMtpModalOpen"
      @closeActivateMtpModal="isActivateMtpModalOpen = false" @initiateMtpActivation="initiateActivation('mtp')" />

  </div>
</template>
<style scoped>
.reviewers-container {
  @apply max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 text-warm-gray-700 dark:text-warm-gray-400
}

.header-container {
  @apply space-y-2 align-middle sm:my-5
}

.header-title {
  @apply text-lg sm:text-xl font-bold leading-7 text-warm-gray-900 dark:text-warm-gray-200
}

.header-description {
  @apply text-sm sm:text-base max-w-3xl
}

.list-container {
  @apply my-6
}

.reviewers-list {
  @apply space-y-6 sm:space-y-8
}

.reviewer-card {
  @apply border border-warm-gray-300 dark:border-true-gray-700 sm:flex sm:justify-between bg-white dark:bg-true-gray-800
}

.card-image {
  @apply w-full sm:w-2/5
}

.card-main {
  @apply relative px-8 py-8 sm:py-10 sm:px-10 lg:py-10 space-y-2 w-full sm:w-3/5
}

.card-name {
  @apply text-lg sm:text-2xl font-extrabold dark:text-warm-gray-300 text-warm-gray-800
}

.card-description {
  @apply text-sm sm:text-base dark:text-warm-gray-400 text-warm-gray-800 mt-3
}

/* .notice-container {
  @apply my-4
} */

.purchase-info {
  @apply space-y-1 dark:text-warm-gray-400 py-2
}

.purchase-info strong {
  @apply dark:text-warm-gray-300
}

/** Button Styles */

.button-base {
  @apply disabled:opacity-40 flex-shrink-0 flex py-3 border border-white border-opacity-25 text-white rounded-md items-center justify-center text-base font-medium mt-6 w-full
}

.button-base--active {
  @apply bg-yellow-500 hover:bg-yellow-400
}

.button-base--disabled {
  @apply cursor-auto pointer-events-none bg-gray-600
}

.button-base--ready {
  @apply bg-indigo-500 hover:bg-indigo-400
}

/** Notice Styles */
.notice-base {
  @apply py-4 px-5 space-y-2 text-xs sm:text-sm my-4
}

.notice-base--gray {
  @apply bg-gray-100 text-gray-800 dark:bg-true-gray-700 dark:bg-opacity-50 dark:text-true-gray-300
}

.notice-base--info {
  @apply bg-blue-100 text-blue-900
}

.notice-base--warn {
  @apply bg-yellow-100 text-yellow-700
}

.reviewers-container .link-base {
  @apply underline font-bold
}

.badge--beta {
  @apply inline-flex items-center rounded-md bg-yellow-400 bg-opacity-10 px-2 py-0 text-xs font-medium text-yellow-500 border border-yellow-400
}
</style>