<script setup>
import { ref, computed } from "vue"
import firebase from "@/firebase/config"
import { ArrowRightIcon, ChevronDoubleLeftIcon, QuestionMarkCircleIcon, PaperClipIcon, LightningBoltIcon, FireIcon } from "@heroicons/vue/solid"
import DefaultUser from "@/components/DefaultUser";
// import PreExamModal from "../components/PreExamModal.vue"


const isWaiting = ref(true)
const user = ref(null)
const school = ref(null)
const subjects = ref([])
const selectedSubject = ref(null)
const isPreExamModalOpen = ref(false)

const bestRecord = computed(() => {
    // return user.value.subjects.filter( e => e.id == 'air-law-and-human-performance')[0]
    return null
})

// const fetchSubjects = () => {
//     return new Promise(async (resolve, reject) => {
//         firebase.firestore().collection("reviewers").doc("lite").collection("subjects").get()
//             .then((querySnapshot) => {
//                 var subjects = [];
//                 querySnapshot.forEach((doc) => {
//                     let data = doc.data()
//                     data.id = doc.id
//                     subjects.push(data)
//                 });
//                 resolve(subjects)
//                 console.log(subjects)
//             })
//             .catch((error) => {
//                 console.error("Error getting subtopic data: ", error);
//                 reject(error)
//             })
//     })
// }

const fetchUser = () => {
    return new Promise(async (resolve, reject) => {
        let id = firebase.auth().currentUser.uid
        firebase.firestore().collection("users").doc(id).get()
            .then(doc => {
                let data = doc.data()
                data.id = doc.id
                resolve(data)
                console.log(data)
            })
            .catch((error) => {
                console.error("Error getting user document:", error)
                reject(error)
            });
    })
}

const fetchSchool = (schoolId) => {
    return new Promise(async (resolve, reject) => {
        firebase.firestore().collection("schools").doc(schoolId).get()
            .then(doc => {
                let data = doc.data()
                data.id = doc.id
                resolve(data)
            })
            .catch((error) => {
                console.error("Error getting user document:", error)
                reject(error)
            });
    })
}

const round = (value, precision) => {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}

const closePreExamModal = () => {
    isPreExamModalOpen.value = false
}

const openPreExamModal = () => {
    isPreExamModalOpen.value = true
    selectedSubject.value = subjects.value[0]
}

const initialize = async () => {
    isWaiting.value = true
    user.value = await fetchUser()
    school.value = await fetchSchool(user.value.school)
    isWaiting.value = false
}
initialize()

</script>
<template>

    <!-- Announcement Banner -->
    <div
        class="pointer-events-auto overflow-hidden rounded-lg bg-gradient-to-r from-indigo-800 to-indigo-500 shadow-lg ring-1 ring-black ring-opacity-5 mb-4 w-full">
        <div class="p-4">
            <div class="flex items-start">
                <div class="flex-shrink-0">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                        class="h-6 w-6 text-white">
                        <path fill-rule="evenodd"
                            d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
                            clip-rule="evenodd" />
                    </svg>
                </div>
                <div class="ml-2 w-0 flex-1 pt-0.5 items-center lg:flex lg:space-x-2">
                    <p class="text-sm font-bold text-white">IMPORTANT:</p>
                    <p class="text-sm text-indigo-100">This reviewer is in BETA mode! Some features are still under
                        development but you can start practicing all the subjects for your MTP Exam.</p>
                    <!-- <a href="#" @click="openAnnounceModal" class="hover:opacity-75 text-sm font-semibold text-white text-white">Learn More →</a> -->
                </div>
            </div>
        </div>
    </div>

    <div class="max-w-md mx-auto py-6">
        <div v-if="!isWaiting" class="space-y-6 mb-20 px-4">
            <!--  -->
            <nav class="flex justify-between items-center" aria-label="Breadcrumb">
                <div class="flex items-center">
                    <router-link to="/reviewers"
                        class="text-sm font-medium text-warm-gray-500 hover:text-warm-gray-600 flex items-center"
                        aria-current="page">
                        <ChevronDoubleLeftIcon class="flex-shrink-0 h-6 w-6" aria-hidden="true" />
                    </router-link>
                </div>
                <div class="text-sm font-medium text-warm-gray-500">
                    <span class="hidden md:block">MTP Reviewer</span>
                    <span class="block md:hidden">MTP Reviewer</span>
                </div>
                <!-- FAQ Menu -->
                <a href="#">
                    <QuestionMarkCircleIcon class="flex-shrink-0 h-6 w-6 text-warm-gray-500 hover:text-warm-gray-600"
                        aria-hidden="true" />
                </a>
            </nav>
            <!--  -->
            <div class="flex flex-col">
                <div class="w-full flex justify-center z-10 -mb-2">
                    <div class="relative h-30 w-24">
                        <div class="flex-shrink-0 h-24 w-24 rounded-full bg-white overflow-hidden">
                            <img v-if="user.photo" class="object-cover h-24 w-24 border-4 border-white rounded-full"
                                :src="user.photo" rel="preload" alt="">
                            <DefaultUser v-else class="" />
                        </div>
                        <div class="absolute flex-shrink-0 rounded-full bg-white h-12 w-12 -bottom-2 -right-4">
                            <img class="object-cover border-4 h-12 w-12 border-white rounded-full" :src="school.logoUrl"
                                rel="preload" alt="">
                        </div>
                    </div>
                </div>
                <div v-if="user" class="profile-card -mt-10 pb-6">

                    <div class="flex flex-col items-center mt-8 pt-6 text-center px-3">
                        <h1 class="text-warm-gray-400 text-md font-semibold my-2 flex items-center space-x-1">
                            <span>{{ user.firstname }} {{ user.lastname }}</span>
                            <svg v-if="user.isLicensed && user.isVerified" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"
                                class="w-4 h-4 text-blue-500">
                                <path fill-rule="evenodd"
                                    d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                    clip-rule="evenodd"></path>
                            </svg>
                        </h1>
                        <div class="flex items-start space-x-1 text-warm-gray-400 text-sm font-normal">
                            <span>📚 {{ user.course }}</span>
                        </div>
                        <div class="flex items-start space-x-1 text-warm-gray-400 text-sm font-normal">
                            <span>🏫 {{ school.name }} ({{ school.shorthand }})</span>
                        </div>
                        <div class="flex flex-inline space-x-2 mt-4 -mb-3">
                            <!-- Milestones -->

                            <!-- <div class="flex w-full justify-between space-x-2">
                            <div class="w-28 flex flex-col items-center">
                            <div :class="[(bestRecord && bestRecord.milestones.includes('passer')) ? 'bg-blue-200' : 'bg-true-gray-700', 'mx-auto flex-grow-0 rounded-full w-12 h-12 p-3']">
                                <PaperClipIcon :class="[(bestRecord && bestRecord.milestones.includes('passer')) ? 'text-blue-500' : 'text-true-gray-600', 'w-6 h-6']" />
                            </div>
                            </div>
                            <div class="w-28 flex flex-col items-center">
                            <div :class="[(bestRecord && bestRecord.milestones.includes('speedrunner')) ? 'bg-purple-200' : 'bg-true-gray-700', 'mx-auto flex-grow-0 rounded-full w-12 h-12 p-3']">
                                <LightningBoltIcon :class="[(bestRecord && bestRecord.milestones.includes('speedrunner')) ? 'text-purple-500' : 'text-true-gray-600', 'w-6 h-6']" />
                            </div>
                            </div>
                            <div class="w-28 flex flex-col items-center">
                            <div :class="[(bestRecord && bestRecord.milestones.includes('master')) ? 'bg-yellow-200' : 'bg-true-gray-700', 'mx-auto flex-grow-0 rounded-full w-12 h-12 p-3']">
                                <FireIcon :class="[(bestRecord && bestRecord.milestones.includes('master')) ? 'text-yellow-500' : 'text-true-gray-600', 'w-6 h-6']" />
                            </div>
                            </div>
                        </div> -->
                        </div>
                    </div>
                    <!--  -->

                    <!-- <div class="grid grid-cols-3 mt-8">
                    <div class="border-t border-true-gray-700 py-3 px-4 sm:px-6">
                        <p class="text-warm-gray-400 text-sm font-medium leading-6 text-gray-400">High Score</p>
                        <p v-if="bestRecord" class="flex items-baseline gap-x-1">
                            <span class="text-2xl sm:text-3xl font-semibold tracking-tight text-white">{{ round(bestRecord.score, 1) }}</span>
                            <span class="text-sm text-warm-gray-400">%</span>
                        </p>
                        <p v-else class="flex items-baseline gap-x-1">
                            <span class="text-2xl sm:text-3xl font-semibold tracking-tight text-white">0</span>
                            <span class="text-sm text-warm-gray-400">%</span>
                        </p>
                    </div>
                    <div class="border-t border-true-gray-700 py-3 px-4 sm:px-6 border-l">
                        <p class="text-warm-gray-400 text-sm font-medium leading-6 text-gray-400">Best Time</p>
                        <p v-if="bestRecord" class="flex items-baseline gap-x-1">
                            <span class="text-2xl sm:text-3xl font-semibold tracking-tight text-white">{{ (bestRecord.time > 60) ? round(bestRecord.time/60, 1) : bestRecord.time }}</span>
                            <span v-if="bestRecord.time > 60" class="text-sm text-warm-gray-400">mins</span>
                            <span v-else="bestRecord.time > 60" class="text-sm text-warm-gray-400">secs</span>
                        </p>
                        <p v-else class="flex items-baseline gap-x-1">
                            <span class="text-2xl sm:text-3xl font-semibold tracking-tight text-white">0</span>
                            <span class="text-sm text-warm-gray-400">secs</span>
                        </p>
                    </div>
                    <div class="border-t border-true-gray-700 py-3 px-4 sm:px-6 border-l">
                        <p class="text-warm-gray-400 text-sm font-medium leading-6 text-gray-400">Skillpoints</p>
                        <p v-if="user.skillpoints > 1000000" class="flex items-baseline gap-x-1">
                            <span class="text-2xl sm:text-3xl font-semibold tracking-tight text-white">
                                {{  round(user.skillpoints/1000000, 1) }}
                            </span>
                            <span class="text-sm text-warm-gray-400">M</span>
                        </p>
                        <p v-else-if="user.skillpoints > 1000" class="flex items-baseline gap-x-1">
                            <span class="text-2xl sm:text-3xl font-semibold tracking-tight text-white">
                                {{  round(user.skillpoints/1000, 1) }}
                            </span>
                            <span class="text-sm text-warm-gray-400">k</span>
                        </p>
                        <p v-else class="flex items-baseline gap-x-1">
                            <span class="text-2xl sm:text-3xl font-semibold tracking-tight text-white">
                                {{  user.skillpoints/1000 }}
                            </span>
                        </p>
                    </div>
                </div> -->
                </div>

            </div>
            <!-- practice -->
            <div class="card">
                <h1>🚀 Master the subjects!</h1>
                <p>
                    Large aviation companies like Lufthansa and Singapore Airlines conduct entrance exams as an alternative for
                    filtering candidates, especially entry-level applicants. Be ahead of the curve by practicing these 
                    subjects for the entrance exams.
                </p>
                <router-link to="/mtp/subjects">
                    <button type="button" class="btn">
                        <span>View Subjects</span>
                        <ArrowRightIcon class="w-4 h-4 ml-1" />
                    </button>
                </router-link>
            </div>
            <!-- mock exam -->
            <!-- <div class="card">
            <h1>✏️ Perfect the exam!</h1>
            <p>
                Test your knowledge by doing a CAAP licensure mock exam. This is built with time and score criteria that exactly replicates the real one.
            </p>
            <button type="button" class="mt-4 cursor-default rounded-md bg-warm-gray-700 px-3 py-2 text-sm font-semibold text-warm-gray-900 shadow-sm w-full flex items-center justify-center">
                <span>Coming Soon</span>
            </button>
        </div> -->
            <!-- progress -->
            <div class="card">
                <h1>🔎 Review your progress!</h1>
                <p>
                    See the extensive record of your learning progress so far by looking into all your Mock Exam
                    results. Easily identify which areas you need to practice more.
                </p>

                <!-- <button type="button" class="btn">
                    <span>View Exam History</span>
                    <ArrowRightIcon class="w-4 h-4 ml-1" />
                </button> -->
                <button type="button"
                    class="mt-4 cursor-default rounded-md bg-warm-gray-700 px-3 py-2 text-sm font-semibold text-warm-gray-900 shadow-sm w-full flex items-center justify-center">
                    <span>Coming Soon</span>
                </button>

            </div>
            <!-- achievements -->
            <div class="card">
                <h1>🏅 Collect these badges!</h1>
                <p>
                    View all Achievements that can be obtained by reaching different Milestones in our Mock Exams. Boost
                    your exam skills and collect big Skillpoints in the process.
                </p>
                <!-- <router-link to="/lite/achievements"> -->
                <!-- <button type="button" class="btn">
                    <span>See Achievements List</span>
                    <ArrowRightIcon class="w-4 h-4 ml-1" />
                </button> -->
                <button type="button"
                    class="mt-4 cursor-default rounded-md bg-warm-gray-700 px-3 py-2 text-sm font-semibold text-warm-gray-900 shadow-sm w-full flex items-center justify-center">
                    <span>Coming Soon</span>
                    <!-- <ArrowRightIcon class="w-4 h-4 ml-1" /> -->
                </button>
                <!-- </router-link> -->
            </div>
        </div>
    </div>

    <!-- <PreExamModal :isOpen="isPreExamModalOpen" :subject="selectedSubject" @closeModal="closePreExamModal()" /> -->

</template>

<style scoped>
.btn {
    @apply mt-4 rounded-md bg-yellow-400 px-3 py-2 text-sm font-semibold text-warm-gray-900 shadow-sm hover:bg-yellow-500 w-full flex items-center justify-center
}

h1 {
    @apply font-bold text-warm-gray-200 mt-2
}

.card p {
    @apply text-sm text-warm-gray-400 my-2
}

.card {
    @apply text-base rounded-2xl px-6 py-6 bg-true-gray-800 border border-true-gray-700 bg-opacity-50 border-opacity-50
}

.profile-card {
    @apply text-base rounded-2xl bg-true-gray-800 border border-true-gray-700 bg-opacity-50 border-opacity-50
}
</style>