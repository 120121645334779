import firebase from "@/firebase/config"
import PlusLanding from "../views/PlusLanding.vue"
import PlusSubjectsStudy from "../views/PlusSubjectsStudy.vue"
import PlusSubtopicsStudy from "../views/PlusSubtopicsStudy.vue"
import PlusStudy from "../views/PlusStudy.vue"
import PlusSubjects from "../views/PlusSubjects.vue"
import PlusSubtopics from "../views/PlusSubtopics.vue"
import PlusPractice from "../views/PlusPractice.vue"
import PlusExam from "../views/PlusExam.vue"

import { plusExpirationGuard } from "@/utils/helper"
import store from '@/store';

async function authGuard(to, from, next) {

    /**
     * Is the user authenticated?
     */
    const isAuthenticated = firebase.auth().currentUser;
    
    if (!isAuthenticated) {
        next({ path: '/login', query: { unauthorized: 'true' } })
    } else {


        console.warn(`A flagged user is accessing the CAAP PLUS reviewer.`);
        
        let authUserClaims = await fetchUserClaims();

        // check if user purchased the plus
        if (authUserClaims.caap_plus) {

            // check for caap plus expiration / subscription
            let nextRoutePath = await plusExpirationGuard(authUserClaims, store.getters.user.info)
            console.log('Going to:',nextRoutePath) // if nextRoutePath is null then just proceed to the current path route.
            next(nextRoutePath);

        }else{

            console.error(`You don't have access to CAAP PLUS, rerouting to /reviewers`)
            next({ path: '/reviewers' })
        }


        
        // let claims = await fetchUserClaims()
        // console.log(claims)
        // if(claims.mtp){
        //     next()
        // }else{
        //     next({ path: '/', query: { error: 'unauthorized' } })
        // }
        
        /**
         * @TODO
         * Add feature that enables CAAP Mtp purchase. Make sure to add
         * the necessary claims. Once done, uncomment the section above
         * and remove the line below (i.e. next();)
         */
        // next();
    }


    // /**
    //  * Is the user authenticated?
    //  */
    // const isAuthenticated = firebase.auth().currentUser;
    
    // if (!isAuthenticated) {
    //     next({ path: '/login', query: { unauthorized: 'true' } })
    // } else {
        
    //     let claims = await fetchUserClaims()
    //     if(claims.caap_plus){
    //         next()
    //     }else{
    //         next({ path: '/', query: { error: 'unauthorized' } })
    //     }
        
    //     /**
    //      * @TODO
    //      * Add feature that enables CAAP Mtp purchase. Make sure to add
    //      * the necessary claims. Once done, uncomment the section above
    //      * and remove the line below (i.e. next();)
    //      */
    //     // next();
    // }
}

async function fetchUserClaims() {
    return new Promise(async (resolve, reject) => {
        firebase.auth().currentUser.getIdTokenResult(true)
            .then(result => {
                resolve(result.claims)
            })
            .catch(error => {
                console.error("Error getting user claims", error);
                reject(error)
            })
    })
}


export const plusRoutes = [
    {
        path: '/plus',
        name: 'CAAP Plus',
        component: PlusLanding,
        beforeEnter: authGuard
    },
    {
        path: '/plus/subjects/study',
        name: 'CAAP Plus Subjects Study',
        component: PlusSubjectsStudy,
        beforeEnter: authGuard
    },
    {
        path: '/plus/subtopics/study',
        name: 'CAAP Plus Subtopics Study',
        component: PlusSubtopicsStudy,
        beforeEnter: authGuard
    },
    {
        path: '/plus/study',
        name: 'CAAP Plus Study',
        component: PlusStudy,
        beforeEnter: authGuard
    },
    {
        path: '/plus/subjects',
        name: 'CAAP Plus Subjects',
        component: PlusSubjects,
        beforeEnter: authGuard
    },
    {
        path: '/plus/subtopics',
        name: 'CAAP Plus Subtopics',
        component: PlusSubtopics,
        beforeEnter: authGuard
    },
    {
        path: '/plus/practice',
        name: 'CAAP Plus Practice',
        component: PlusPractice,
        beforeEnter: authGuard
    },
    {
        path: '/plus/exam',
        name: 'CAAP Plus Exam',
        component: PlusExam,
        beforeEnter: authGuard
    },
]